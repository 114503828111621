import React, {useEffect, useState} from 'react';
import { withTranslation,  TFunction  } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import Page from '../containers/page';
import Card from '../containers/card';
import Form from '../containers/form';
import FormFilter from '../containers/form-filter';
import Button from '../controls/buttons/button';
import TextField from '../controls/fields/textField';
import { ColumnDefinition } from '../table/ColumnDefinition';
import SelectField from '../controls/fields/selectField';
import SubmitButton from '../controls/buttons/submitButton';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { ReactComponent as EditIconSVG } from "../../../node_modules/itd-common/assets/icons/edit.svg";
import './DataCommerce.scss'
import { WebCache } from "../../services/WebCache";
import { OnboardingService } from '../../services/OnboardingService';
import { AxiosResponse } from "axios";
import {
    IOnboardingQuestionResponse,
    IOnboardingQuestionValidationRequest,
    IOnboardingQuestionValidationResponse
} from "../../models/IOnboardingData";
import { ToastContainer, toast } from "react-toastify";
import i18next from "i18next";
import Swal from 'sweetalert2';
import EmailField from '../controls/fields/emailField';
import LabelMessage from '../LabelMessage';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from "../../../node_modules/itd-common/assets/images/brand/logo.svg";
import {UserService} from "../../services/UserService";


interface IProps {
    t: TFunction
}

interface QuestionOptionProps {
    label: string;
    name: string;
    questionIndex: number
    answerIndex: number
}

interface SecurityQuestionSectionProps {
    question: string;
    options: string[];
    sectionIndex:number
}

interface IFilters {
    merchantDocument: string;
    merchantNumber: string;
}

interface MerchantInfoResponse {
    success: boolean;
    errorDesc?: string;
    merchant: {
        bussinesName: string;
        merchantNumber: string;
        fiscalNumber: string;
        name: string;
        addressStreet: string;
        addressStreetNumber: string;
        addressLocation: string;
        addressPostCode: number;
        mccDesc: string;
        mccDetailDesc: string;
        entityName: string;
        paymentMethodDesc: string;
        paymentAccount: number;
        codCatIvaDesc: string;
        grossIncome: string;
        state: number;
    };
}

type AddDocumentFormType = { fiscalNumber: string, merchant: string,email:string,emailConfirmation:string};
const T_PREFIX: string = 'pages.dataCommerce.';




const DataCommerce: React.FC<IProps> = ({ t }: IProps) => {

    const [securityQuestions, setSecurityQuestions] = useState<IOnboardingQuestionResponse>({ questions: [], answers: [], errorLog: '' });
    const [userAnswers, setUserAnswers] = useState<IOnboardingQuestionValidationRequest>({ answers: [], merchantNumber: '', fiscalDocument: '', email: '' });
    const [commerceData, setCommerceData] = useState<any[]>([]);
    const [fiscalesData, setFiscalesData] = useState<any[]>([]);
    const [filters, setFilters] = useState<IFilters>({ merchantDocument: WebCache.getAllMerchantDocuments(false,true)[0].label+"", merchantNumber: ""});
    const [showAddDocument, setShowAddDocument] = useState<boolean>(false);
    const [showQuestions, setShowQuestions] = useState<boolean>(false);
    const [documentForm, setDocumentForm]  = useState<AddDocumentFormType>({ fiscalNumber: '', merchant: '',email:WebCache.getCurrentUser()?.email+"",emailConfirmation:WebCache.getCurrentUser()?.email+""});
    const [fiscalDocuments, setfiscalDocuments] = useState<Array<HTMLOptionElement>>(new OptionMapperHelper(t).translateOptionValues(WebCache.getAllMerchantDocuments(false,true)) as Array<HTMLOptionElement>);
    const [merchantNumber, setMerchantNumber]=useState<Array<HTMLOptionElement>>(new OptionMapperHelper(t).translateOptionValues(OptionMapperHelper.getAllMerchantNumbersOptions(WebCache.getAllMerchantDocuments(false,true)[0].label,false,true)) as Array<HTMLOptionElement>);

    const currentUser = WebCache.getCurrentUser()?.countryId;
    let fiscalNumber;
    let createDocument;
    let addressLocation;

    if (currentUser !== undefined && currentUser === 2) {
        fiscalNumber = `${T_PREFIX}fiscalURU`;
        createDocument=`${T_PREFIX}documentURU`;
        addressLocation=`${T_PREFIX}comercioColumn.addressLocation`;
    } else {
        fiscalNumber = `${T_PREFIX}fiscalARG`;
        createDocument=`${T_PREFIX}documentARG`;
        addressLocation=`${T_PREFIX}comercioColumn.addressLocationArg`;
    }




    useEffect(() => {
        if(filters.merchantDocument!=null&&filters.merchantDocument.length>0){
            setFilters({...filters,merchantNumber:OptionMapperHelper.getAllMerchantNumbersOptions(filters.merchantDocument,false,true)[0].key})
            setMerchantNumber(new OptionMapperHelper(t).translateOptionValues(OptionMapperHelper.getAllMerchantNumbersOptions(filters.merchantDocument,false,true)) as Array<HTMLOptionElement>)
        }
    }, [filters.merchantDocument]); 

    const comercioColumnDefinitions: ColumnDefinition[] = [
        { key: 'NroComercio', label: t(`${T_PREFIX}comercioColumn.merchantNumber`) },
        { key: 'NombreFantasia', label: t(`${T_PREFIX}comercioColumn.bussinesName`) },
        { key: 'RUT', label: t(fiscalNumber) },
        { key: 'RazonSocial', label: t(`${T_PREFIX}comercioColumn.name`) },
        { key: 'Calle', label: t(`${T_PREFIX}comercioColumn.addressStreet`) },
        { key: 'Nro', label: t(`${T_PREFIX}comercioColumn.addressStreetNumber`) },
        { key: 'Departamento', label: t(addressLocation) },
        { key: 'CodigoPostal', label: t(`${T_PREFIX}comercioColumn.addressPostCode`) }
    ];
    const fiscalColumnDefinitions: ColumnDefinition[] = [
        { key: 'Rubro', label: t(`${T_PREFIX}fiscalColumn.mccDesc`) },
        { key: 'Ramo', label: t(`${T_PREFIX}fiscalColumn.mccDetailDesc`) },
        { key: 'EntidadPagadora', label: t(`${T_PREFIX}fiscalColumn.entityName`) },
        { key: 'FormaPago', label: t(`${T_PREFIX}fiscalColumn.paymentMethodDesc`) },
        { key: 'CuentaAcreditacion', label: t(`${T_PREFIX}fiscalColumn.paymentAccount`) },
        { key: 'CategoriaIva', label:t(`${T_PREFIX}fiscalColumn.codCatIvaDesc`) },
        { key: 'CodigoIngresosBrutos', label: t(`${T_PREFIX}fiscalColumn.grossIncome`) },
        { key: 'Estado', label: t(`${T_PREFIX}fiscalColumn.state`) }
    ];

    const getMerchantInfo = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const merchantNumber = filters.merchantNumber;
        const fiscalDocument = filters.merchantDocument;

        try {
            const response: AxiosResponse<MerchantInfoResponse> = await OnboardingService.getAllMerchantInfo({
                merchantNumber,
                fiscalDocument
            });
            if (response.data.success) {
                const merchant = response.data.merchant;
                setCommerceData([{
                    NroComercio: merchant.merchantNumber,
                    NombreFantasia: merchant.name,
                    RUT: merchant.fiscalNumber,
                    RazonSocial: merchant.bussinesName,
                    Calle: merchant.addressStreet,
                    Nro: merchant.addressStreetNumber,
                    Departamento: merchant.addressLocation,
                    CodigoPostal: merchant.addressPostCode
                }]);
                setFiscalesData([{
                    Rubro: merchant.mccDesc,
                    Ramo: merchant.mccDetailDesc,
                    EntidadPagadora: merchant.entityName,
                    FormaPago: merchant.paymentMethodDesc,
                    CuentaAcreditacion: merchant.paymentAccount,
                    CategoriaIva: merchant.codCatIvaDesc,
                    CodigoIngresosBrutos: merchant.grossIncome,
                    Estado: merchant.state
                }]);
            } else {
                setCommerceData([]);
                setFiscalesData([]);
                console.error('Comercio no encontrado', response.data.errorDesc);
            }
        } catch (error) {
            toast.error('Error al obtener información del comerciante');
            console.error('Error:', error);
        }
    }


    const closeAddDocumentPage = () => {
        setShowAddDocument(false);
    }

    const getInitialQuestions = async () => {
        try {
            const res: AxiosResponse<IOnboardingQuestionResponse> = await OnboardingService.getOnboardingQuestionsInternal(documentForm.fiscalNumber, documentForm.merchant, documentForm.email);
            if (res.data.errorLog != null && res.data.errorLog.length > 0) {
                toast.error(i18next.t(res.data.errorLog) + "");
                return;
            }
            setSecurityQuestions(res.data);
            let userAnswers: IOnboardingQuestionValidationRequest = { answers: [], merchantNumber: documentForm.merchant.toString(), fiscalDocument: documentForm.fiscalNumber.toString(), email: WebCache.getCurrentUser()?.email+"" };
            for (let i = 0; i < res.data.answers.length; i++) {
                userAnswers.answers.push({ respID: (i + 1).toString(), selectedResponse: "" });
            }
            setUserAnswers(userAnswers);
            setShowAddDocument(false);
            setShowQuestions(true);
            //return renderQuestionRequest();
        } catch (error) {
            console.error("Error al crear el comercio:", error);
            throw new Error("Error al crear el comercio");
        }
    };

    const isAnswerSelected = (questionIndex: number, answerIndex: number) => {
        return userAnswers.answers[questionIndex]?.selectedResponse === (answerIndex + 1).toString();
    };
    
    const handleAnswerChange = (questionIndex: number, answerIndex: number) => {
        const updatedAnswers = [...userAnswers.answers];
        updatedAnswers[questionIndex].selectedResponse = (answerIndex + 1).toString();
        setUserAnswers({ ...userAnswers, answers: updatedAnswers }); 
    };

    const validateAnswers = (): boolean => {
        const allQuestionsAnswered = userAnswers.answers.every(answer => answer.selectedResponse.trim() !== '');
        if (!allQuestionsAnswered) {
            toast.error("Por favor, responda todas las preguntas de seguridad.");
            return false;
        }
        return true;
    };

    const QuestionOption: React.FC<QuestionOptionProps> = ({ label, name, answerIndex, questionIndex }) => (
        <>
            <input type="radio" name={name} onClick={(event) => handleAnswerChange(questionIndex, answerIndex)} checked={isAnswerSelected(questionIndex, answerIndex)} />
            <label>{label}</label>
        </>
    );

    const SecurityQuestionSection: React.FC<SecurityQuestionSectionProps> = ({ question, options, sectionIndex }) => (
        <>
            <p className='textQuestion'>{question}</p>
            <div className='mapQuestion'>
                {options.map((option, index) => (
                    <QuestionOption
                        key={index}
                        name={`pregunta-${sectionIndex}`}
                        label={option}
                        questionIndex={sectionIndex}
                        answerIndex={index}
                    />
                ))}
            </div>
            <div className='lineContainer'></div>
        </>
    );

    const renderQuestionRequest = (): React.ReactElement => {
        const handleValidateSecurityQuestions = () => {
            if (!validateAnswers()) {
                return;
            }
            OnboardingService.addMerchantToClient(userAnswers).then(function(res: AxiosResponse<IOnboardingQuestionValidationResponse>) { 
                try {
                    if (Number.parseInt(res.data.returnCode) === 0) {
                        Swal.fire(i18next.t('addDocumentSuccess')+"", i18next.t('needToRestartAfterAddDocument')+"").then(function () {
                           UserService.logout();
                        });
                    }else
                        Swal.fire(i18next.t('errorTitle')+"", i18next.t((res != null && res.data != null && res.data.errorLog != null && res.data.errorLog.length > 0) ? res.data.errorLog : 'securityQuestionError')+"").then(function () {

                        });
                } catch (e) {
                    Swal.fire(i18next.t('errorTitle')+"", i18next.t((res != null && res.data != null && res.data.errorLog != null && res.data.errorLog.length > 0) ? res.data.errorLog : 'securityQuestionError')+"").then(function () {

                    });
                }

            })
        };
        return (
            <Page>
                <>
                <React.Fragment>
                    <ToastContainer autoClose={3000} ></ToastContainer>
                        <div className="login">
                            <div className="page">
                                <div className="container-login100 ">
                                    <div className="wrap-login100 p-6 onboardingForm">
                                        <span className="login100-form-title mb-5">
                                            <h3>{t(`${T_PREFIX}titleSecurity`)} </h3>
                                        </span>
                                        <p>{t(`${T_PREFIX}merchantInfo`)} </p>
                                        {securityQuestions.questions.map((questionData, index) => (
                                            <SecurityQuestionSection
                                                key={index}
                                                question={questionData.questionDescription}
                                                options={securityQuestions.answers[index]}
                                                sectionIndex={index}
                                            />
                                        ))}
                                        <div className='confirm-container row'>
                                            <div className='boton col-md-6'>
                                                <div className='boton__click'>
                                                    <SubmitButton className='mb-4 sendingButton' onClick={handleValidateSecurityQuestions}>
                                                        <div className='submit-button-container'>
                                                            <span className='ms-3'>{t(`common.action.confirm`)}</span>
                                                            <i className='fe fe-arrow-right submit-button-container__icon me-3'></i>
                                                        </div>
                                                    </SubmitButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                </>        
            </Page>  
        )
    }
/*
    const renderOnboardingResult = (): React.ReactElement => {
        return (
            <React.Fragment>
                <div className="page mail-password">
                    <div className=""> 
                        <div className="container-mail">
                            <div className="form-mail">
                                <div className='brand-logo-container'>
                                    <LogoSVG className='brand-logo-container__image' ></LogoSVG>
                                </div>
                                <span className='text-check' > {t(`${T_PREFIX}successAddMerchant`)}</span>
                                <Link to={'/dashboard'} className="back-login"> {t(`${T_PREFIX}returnToLogin`)}</Link>                                                      
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }*/

    const renderAddDocumentPage = () => { 
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let merchantDocument;
        let insertFiscalNumber;

        if (currentUser !== undefined && currentUser === 2) {
            merchantDocument = `${T_PREFIX}addDocumentMerchantURU`;
            insertFiscalNumber = `${T_PREFIX}insertFiscalURU`;
        } else {
            merchantDocument = `${T_PREFIX}addDocumentMerchantARG`;
            insertFiscalNumber = `${T_PREFIX}insertFiscalARG`;
        }

        const isSubmitDisabled = (): boolean => {
            const isAnyFieldEmpty = !documentForm.fiscalNumber || !documentForm.merchant;
    
            return isAnyFieldEmpty;
        };
    
        const handleChangeSecurity = async () => {
            try {
                await getInitialQuestions();
            } catch (error) {
                console.error("Error al registrar comercio", error);
                toast.error("Error al registrar comercio");
            }
        };
        return (
            <Page>
                <>
                    <div className="row">
                    <h2>{t(merchantDocument)}</h2>
                        <div className="col-lg-12">
                            <Card >
                                <>
                                    <Form model={[documentForm, setDocumentForm]} >
                                        <div className="row">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className='mb-5'>
                                                        <p>{t(insertFiscalNumber)}</p>
                                                        <TextField
                                                            attr="fiscalNumber"
                                                            label={insertFiscalNumber}
                                                            autoFocus
                                                            required
                                                        ></TextField>
                                                    </div>
                                                    <div className='mt-5'>
                                                        <p> {t(`${T_PREFIX}insertMerchant`)}</p>
                                                        <TextField
                                                            attr="merchant"
                                                            label='Nro comercio'
                                                            required
                                                        ></TextField>
                                                    </div>
                                                    <div className='mt-5'>

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            </Card>
                            <div className="d-flex justify-content-end">
                                <Button
                                    className='m-3 me-0 '
                                    onClick={closeAddDocumentPage}
                                    label="cancel"
                                    color="white"
                                    noBlock
                                ></Button>
                                <SubmitButton
                                    className='m-3'
                                    label={`common.action.confirm`}
                                    noBlock
                                    onClick={handleChangeSecurity}
                                    disabled={isSubmitDisabled()}
                                ></SubmitButton>
                            </div>
                        </div>
                    </div>
                </>
            </Page>
        )
    };

    return (
        <React.Fragment>
            {showAddDocument ?
                renderAddDocumentPage()
            : (showQuestions?renderQuestionRequest():
                    <Page>
                        <>
                            <div className="row">
                                <h2>{t(`${T_PREFIX}title`)}</h2>
                                <div className="col-lg-12">
                                    <Card>
                                        <>
                                            <FormFilter model={[filters, setFilters]} onSubmit={getMerchantInfo} >
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <SelectField attr='merchantDocument' label={fiscalNumber} options={fiscalDocuments}></SelectField>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <SelectField attr='merchantNumber' label={`merchantNumber`} options={merchantNumber}></SelectField>
                                                    </div>
                                                </div>
                                            </FormFilter>
                                            <nav className="nav mt-3">
                                                <span>
                                                    <Button
                                                        color="transparent"
                                                        className="border-0 mt-0"
                                                        noBlock
                                                        onClick={() => setShowAddDocument(true)}
                                                    >
                                                        <>
                                                            <EditIconSVG />
                                                            <span className='ms-2 text-dark'>{t(createDocument)}</span>
                                                        </>
                                                    </Button>
                                                </span>
                                            </nav>
                                            <div className='lineContainer'></div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-container">
                                                        <h2>{t(`${T_PREFIX}merchantDate`)}</h2>
                                                        <table className="styled-table">
                                                            <thead className='titleTable'>
                                                                <tr >
                                                                    {comercioColumnDefinitions.map((column, index) => (
                                                                        <th key={index}>{column.label}</th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {commerceData && commerceData.length > 0 ? (
                                                                    commerceData.map((rowData, rowIndex) => (
                                                                        <tr key={rowIndex}>
                                                                            {comercioColumnDefinitions.map((column, columnIndex) => (
                                                                                <td key={columnIndex}>{rowData[column.key]}</td>
                                                                            ))}
                                                                        </tr>
                                                                    ))
                                                                ) : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    <div className="table-container">
                                                        <h2>{t(`${T_PREFIX}fiscalDate`)}</h2>
                                                        <table className="styled-table">
                                                            <thead>
                                                                <tr>
                                                                    {fiscalColumnDefinitions.map((column, index) => (
                                                                        <th key={index}>{column.label}</th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {fiscalesData && fiscalesData.length > 0 ? (
                                                                    fiscalesData.map((rowData, rowIndex) => (
                                                                        <tr key={rowIndex}>
                                                                            {fiscalColumnDefinitions.map((column, columnIndex) => (
                                                                                <td key={columnIndex}>{rowData[column.key]}</td>
                                                                            ))}
                                                                        </tr>
                                                                    ))
                                                                ) : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </Card>
                                </div>
                            </div>
                        </>
                    </Page>
                )}
        </React.Fragment>
    );
}
export default withTranslation()(DataCommerce);
