import { AxiosResponse } from 'axios';
import i18n from "i18next";
import React, { useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IPageRequest } from "../models/IPageRequest";
import { IManagementUser } from '../models/IUser';
import { Option } from '../models/Option';
import { IGetUserWebModelRequest } from '../requests/IGetUserWebModelRequest';
import { UserService } from '../services/UserService';
import { WebCache } from "../services/WebCache";
import Page from './containers/page';
import Card from './containers/card';
import CustomModal from './containers/customModal';
import Form from './containers/form';
import FormFilter from './containers/form-filter';
import Button from './controls/buttons/button';
import EmailField from './controls/fields/emailField';
import PasswordField from './controls/fields/passwordField';
import TextField from './controls/fields/textField';
import TypeaheadField from './controls/fields/typeaheadField';
import { ColumnDefinition, textColumnDefinition, actionColumnDefinition } from './table/ColumnDefinition';
import CustomTable from './table/CustomTable';
import SelectField from './controls/fields/selectField';
import SubmitButton from './controls/buttons/submitButton';
import { Modal } from 'react-bootstrap';
import { ReactComponent as IconEditSVG } from "../../node_modules/itd-common/assets/icons/edit.svg";
import { ReactComponent as IconTrashSVG } from "../../node_modules/itd-common/assets/icons/trash.svg"; 
import { ReactComponent as LeftArrowSVG } from "../../node_modules/itd-common/assets/icons/left-arrow.svg";
import { ReactComponent as IconAddSVG } from "../../node_modules/itd-common/assets/icons/icon-add-ligth.svg";
import CheckBoxField from './controls/fields/checkBoxField';

interface IFilters {
    email: string,
    roles: Array<Option>
}

interface IUserForm {
    id: string,
    email: string,
    password: string,
    passwordConfirm: string,
    userName: string,
    name: string,
    roles: Array<Option>,
    terminals: Array<Option>,
    clientDocuments: Array<Option>,
    phoneNumber: string
}

interface IProps {
    t: TFunction
}

interface IState {
    filters: IFilters,
    roleOptionList: Array<Option>,
    users: IManagementUser[],
    mainPage: number,
    pageRequest: IPageRequest,

    userForm: IUserForm,
    terminalOptionList: Array<Option>,
    clientDocumentOptionList: Array<Option>,
    errors: string[],
    validationError: string,
    showEditUserModal: boolean,
    userIdToDelete: string,
    roleOperator: string,
}



const T_PREFIX: string = 'pages.usersManagement.';
class UsersManagement extends React.Component<IProps, IState> {

    private columnDefinitions: Array<ColumnDefinition>;
    private validationErrorTimeout: any;

    componentDidMount() {
             this.getUsers();
    }

    constructor(props: IProps) {
        super(props);

        this.renderDeleteUserModal = this.renderDeleteUserModal.bind(this);
        this.renderCreateAndUpdateUserModal = this.renderCreateAndUpdateUserModal.bind(this);

        this.state = {
            filters: {
                email: "",
                roles: []
            },
            mainPage: 0,
            pageRequest: {
                Skip: 0,
                Take: 0
            },
            users: [],
            userForm: {
                id: "",
                email: "",
                password: "",
                passwordConfirm: "",
                userName: "",
                name: "",
                roles: [],
                terminals: [],
                clientDocuments: [],              
                phoneNumber: ""
            },
            roleOptionList: [],
            terminalOptionList: [],
            clientDocumentOptionList: [],
            errors: [],
            validationError: "",
            showEditUserModal: false,
            userIdToDelete: "",
            roleOperator: ""
        }

        for (let terminal of WebCache.getAllTerminals()) {
            if (terminal.terminalNumber !== "0000")
                this.state.terminalOptionList.push({
                    value: terminal,
                    label: `${terminal.terminalNumber} - ${terminal.branchName} ${terminal.locationName} ${terminal.departmentName}`,
                    key: terminal.terminalNumber,
                    disabled: false
                });
        }

        for (let role of WebCache.getAllRoles()) {
            this.state.roleOptionList.push({
                value: role,
                label: i18n.t(role),
                key: role,
                disabled: false
            });
        }


        let clientDocuments= WebCache.getAllTerminals().map(terminal => { return terminal.clientDocument});
        let filteredClientDocuments = clientDocuments.filter(clientDocument => clientDocument !== null && clientDocument !== undefined && clientDocument !== '');
        for (let clientDocument of filteredClientDocuments) {
            this.state.clientDocumentOptionList.push({
                value: clientDocument,
                label: i18n.t(clientDocument),
                key: clientDocument,
                disabled: false
            });
        }

        const T_PREFIX_TABLE: string = `${T_PREFIX}registeredUsersTable.`;
        this.columnDefinitions = [
            textColumnDefinition({ key: 'email', label: `${T_PREFIX_TABLE}email`,fixedWidth: 160}),
            textColumnDefinition({ key: 'phoneNumber', label: `${T_PREFIX_TABLE}phoneNumber`,fixedWidth: 80 }),
            textColumnDefinition({ key: 'userName', label: `${T_PREFIX_TABLE}userName`,fixedWidth: 80 }),
            textColumnDefinition({ key: 'name', label: `${T_PREFIX_TABLE}name`,fixedWidth: 80 }),
            textColumnDefinition({ key: 'roles', label: `${T_PREFIX_TABLE}roles`, mapValue: (user: IManagementUser) => { 
                const roles: Array<string> = user.roles;
                return roles.map((role) => {
                    const roleOption: Option | undefined = this.state.roleOptionList.find((roleItem: Option) => roleItem.value === role);
                    return (roleOption) ? roleOption.label : role;
                }).join(', ');
            },fixedWidth: 80 }),
            textColumnDefinition({ key: 'terminals', label: `${T_PREFIX_TABLE}terminals`,fixedWidth: 80}),
            actionColumnDefinition({ key: 'edit', action: { function: this.editUser.bind(this), icon: <IconEditSVG className='icon-edit'></IconEditSVG>},fixedWidth: 2}),
            actionColumnDefinition({ key: 'delete', action: { function: this.deleteUserModal.bind(this), icon: <IconTrashSVG className='icon-delete'></IconTrashSVG>},fixedWidth: 2 })
        ];
    }

    getUsersRequest = (all: boolean) => {
        const tranWebModel: IGetUserWebModelRequest = {
            Email: this.state.filters.email,
            Profile: this.state.filters.roles.map((op) => { return op.value }),
            Skip: all ? 0 : (this.state.pageRequest.Skip),
            Take: all ? 0 : (this.state.pageRequest.Take)
        };
        return tranWebModel;
    }

    getUsers = () => {
        UserService.getAll(this.getUsersRequest(false)).then((response: AxiosResponse<IManagementUser[]>) => {
            if (response === null || response.data === null || response.data.length === 0) {
                this.setState({...this.state,
                    users: [],
                    userIdToDelete: ""
                })
                return;
            }

            this.setState({...this.state,
                users: response.data,
                userIdToDelete: ""
            });
        }).catch(error => {
            this.setState({...this.state,
                errors: error.response.data,
                users: [],
                userIdToDelete: ""
            })

            console.log('error:', error);
        });
    }

    validateErrors = () => {
        this.validationErrorTimeout = setTimeout(() => {
            clearTimeout(this.validationErrorTimeout);
            
            const T_PREFIX_VALIDATIONS: string = `${T_PREFIX}modalEditUser.validations.`;
            const createMode: boolean = this.state.userForm.id.length === 0;
            let error: string = "";
            
            if (this.state.userForm.name.length === 0) {
                error = `${T_PREFIX_VALIDATIONS}needName`;
            }
            else if (!this.state.userForm.email.toLowerCase().match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                error = `${T_PREFIX_VALIDATIONS}invalidMail`;
            }
            else if (!this.state.userForm.phoneNumber.toLowerCase().match(
                    /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{3})$/
                ) && !this.state.userForm.phoneNumber.toLowerCase().match(
                    /^\+?\(?(\d{3})[- ]?(\d{2})\)?[- ]?(\d{3})[- ]?(\d{3})$/
                )
            ) {
                error = `${T_PREFIX_VALIDATIONS}invalidPhoneNumber`;
            }
            else if (this.state.userForm.roles.length === 0) {
                error = `${T_PREFIX_VALIDATIONS}needRoles`;
            }
            
            this.setState({
                ...this.state,
                validationError: error
            });
        }, 500);
    }

    saveUser = () => {
        if (this.state.validationError.length > 0) {
            return;
        }
        if (this.state.userForm.id.length === 0) {
            UserService.saveUser({
                id: this.state.userForm.userName,
                email: this.state.userForm.email,
                password: this.state.userForm.password,
                phoneNumber: this.state.userForm.phoneNumber,
                userName: this.state.userForm.userName,
                name: this.state.userForm.name,
                roles: this.state.userForm.roles.map((rol: Option) => rol.value),
                terminals: this.state.userForm.terminals.map((rol: Option) => rol.key + ""),
                clientDocuments: this.state.userForm.clientDocuments.map((rol: Option) => rol.key + ""),
                pathToImage: ""
            })
            .then(
                this.successCallback,
                this.errorCallback
            );
        }
        else {
            UserService.updateUser({
                id: this.state.userForm.id,
                email: this.state.userForm.email,
                password: this.state.userForm.password,
                phoneNumber: this.state.userForm.phoneNumber,
                userName: this.state.userForm.userName,
                name: this.state.userForm.name,
                roles: this.state.userForm.roles.map((rol: Option) => rol.value),
                terminals: this.state.roleOperator != "terminals" ? [] : this.state.userForm.terminals.map((rol: Option) => rol.key + ""),
                clientDocuments: this.state.roleOperator != "clientDocument" ? [] : this.state.userForm.clientDocuments.map((rol: Option) => rol.key + ""),
                pathToImage: ""
            })
            .then(
                this.successCallback,
                (error) => console.log(error)
            );
        }
    }

    findUser(userId: string): IManagementUser | undefined {
        return this.state.users.find((user: IManagementUser) => user.id === userId );
    }

    mapToUserForm(user: IManagementUser | undefined): IUserForm {
        if (!user) {
            return {} as IUserForm;
        }

        let rolesList: Array<Option> = [];
        for (let role of user.roles) {
            const option: Option | undefined = this.state.roleOptionList.find((optionItem: Option) => optionItem.value === role);
            if (option) {
                rolesList.push(option);
            }
        }
        
        let terminalsList: Array<Option> = [];
        for (let role of user.terminals) {
            const option: Option | undefined = this.state.terminalOptionList.find((optionItem: Option) => optionItem.key === role);
            if (option) {
                terminalsList.push(option);
            }
        }

        let clientDocumentsList: Array<Option> = [];
        for (let role of user.clientDocuments) {
            const option: Option | undefined = this.state.clientDocumentOptionList.find((optionItem: Option) => optionItem.key === role);
            if (option) {
                clientDocumentsList.push(option);
            }
        }

        return {
            id: user.id,
            email: user.email,
            password: user.password,
            passwordConfirm: user.password,
            userName: user.userName,
            name: user.name,
            roles: rolesList,
            terminals: terminalsList,
            clientDocuments: clientDocumentsList,
            phoneNumber: user.phoneNumber
        };
    }

    editUser(selectedUser: IManagementUser) {
        const selectedUserFound: IManagementUser | undefined = this.findUser(selectedUser.id);
        let userForm: IUserForm | undefined = this.mapToUserForm(selectedUserFound);
        this.setState({ ...this.state, userForm, showEditUserModal: true });
    }

    closeEditUserModal() {
        this.setState({ ...this.state, showEditUserModal: false });
    }

    deleteUserModal(user: IManagementUser) {
        this.setState({...this.state, userIdToDelete: user.id });
    }

    deleteUser() {
        UserService.deleteUser(this.state.userIdToDelete)
        .then(
            this.successCallback,
            this.errorCallback
        );
    }

    successCallback = (success: AxiosResponse<any>) => {
        if (success.data.success) {
            toast.success(success.data.message);
            this.getUsers();
        }
        else {
            toast.error(success.data.message);
        }

        this.setState({...this.state, showEditUserModal: false});
    }

    errorCallback = (error: any = "") => {
        this.setState({ userIdToDelete: "" });
        
        if (error !== "" && typeof error == "string") {
            toast.error(JSON.stringify(error))
        }
        else {
            toast.error(this.props.t(`${T_PREFIX}modalEditUser.errorTryingToCreateUser`) as string);
        }
    }

    cleanEditFormFields() {
        this.setState({
            ...this.state,
            userForm: {
                roles: [],
                terminals: [],
                clientDocuments: [],
                email: "",
                phoneNumber: "",
                password: "",
                passwordConfirm: "",
                userName: "",
                name: "",
                id: "",
            },
            showEditUserModal: true
        }, this.validateErrors);
    }

    setFilters(filters: IFilters) {
        this.setState({ ...this.state, filters });
    }
    setUserForm(userForm: IUserForm) {
        if (this.state.userForm.id.length === 0) {
            const updatedUserForm = {
                ...userForm,
                userName: this.state.userForm.email,
                password: 'Fiserv.24',
                passwordConfirm: 'Fiserv.24'
            };
            this.setState({ ...this.state, userForm: updatedUserForm });
        } else {
            this.setState({ ...this.state, userForm: userForm });
        }
        
    }

    renderDeleteUserModal() {
        const { t } = this.props;
        const user: IManagementUser | undefined = this.findUser(this.state.userIdToDelete);

        return (
                        <Modal show={this.state.userIdToDelete !== ""}>
                            <div className="container-fluid">
                                <div className="card border p-0 pb-3">
                                    <div className="card-header border-0 pt-3">
                                        <div className="card-options">
                                            <a href={window.location.href} className="card-options-remove" onClick={() => this.setState({ userIdToDelete: "" })} ><i className="fe fe-x"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-body text-center">
                                        <h4 className="h4 mb-0 mt-3">{t(`${T_PREFIX}registeredUsersTable.modalDelete.confirmDeleteUser`)}</h4>
                                        <p className="card-text">{t(`${T_PREFIX}registeredUsersTable.modalDelete.warning`)}</p>
                                    </div>
                                    <div className="card-footer text-center border-0 pt-0">
                                        <div className="row text-center" >
                                            <div className="col-md-6" >
                                                <Button
                                                    onClick={() => this.setState({ userIdToDelete: "" })}
                                                    label="cancel"
                                                    color="white"
                                                    noBlock
                                                ></Button>
                                            </div>
                                            <div className="col-md-6" >
                                                <Button
                                                    onClick={this.deleteUser.bind(this)}
                                                    label={`${T_PREFIX}delete`}
                                                    noBlock
                                                ></Button>                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
        );
    }

    renderCreateAndUpdateUserModal() {
        const { t } = this.props;
        const createMode: boolean = this.state.userForm.id.length === 0;        
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let merchantLabel;

        if (currentUser !== undefined && currentUser === 2) {
            merchantLabel = `${T_PREFIX}registeredUsersTable.clientDocumentRou`;
        } else {
            merchantLabel = `${T_PREFIX}registeredUsersTable.clientDocumentArg`;
        }

        return (
            <Page>
                <>
                    <div className="row">
                        <div className="col-md-3">
                            <h2>{t(`${T_PREFIX}${createMode ? 'createUser' : "editUser"}`)}</h2>
{/*                             <a className='d-flex d-inline' type="button" onClick={()=> this.setState({...this.state, showEditUserModal: false})}> 
                                <LeftArrowSVG className="ms-3 me-2 fs-5 pe-1 fw-bold"></LeftArrowSVG><h4 className='w-50'>{t(`common.action.return`)}</h4>
                            </a> */}
                        </div>
                        <div className="col-lg-12 mt-5">
                            <Card title={`${T_PREFIX}formCreateUserTitle`}>
                                <>
                                <Form model={[ this.state.userForm, this.setUserForm.bind(this) ]} >
                                    <div className="row">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <EmailField
                                                    attr="email"
                                                    label={`${T_PREFIX}modalEditUser.email`}
                                                    onChange={this.validateErrors}
                                                    required
                                                ></EmailField>
                                                
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    attr="name"
                                                    label={`${T_PREFIX}modalEditUser.name`}
                                                    onChange={this.validateErrors}
                                                    required
                                                ></TextField>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextField
                                                    attr="email"
                                                    label={`${T_PREFIX}modalEditUser.userName`}
                                                    disabled={true} 
                                                    onChange={this.validateErrors}
                                                    autoFocus
                                                ></TextField>
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    attr='phoneNumber'
                                                    label={`${T_PREFIX}modalEditUser.phoneNumber`}
                                                    onChange={this.validateErrors}
                                                    required
                                                    autoFocus
                                                ></TextField>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TypeaheadField
                                                    attr="roles"
                                                    label={`${T_PREFIX}modalEditUser.roles`}
                                                    options={this.state.roleOptionList}
                                                    onChange={(value) => {this.validateErrors()}}
                                                    required
                                                ></TypeaheadField>
                                                {this.state.userForm.roles.some(role => role.value === 'Operator') && (
                                                        <><div className='mt-3 d-block'>
                                                            <CheckBoxField id={"none"} label={"Ninguno"} onChange={(event) => this.setState({ ...this.state, roleOperator: "none" })} checked={this.state.roleOperator === "none"}></CheckBoxField>
                                                        </div><div className='mt-3 d-block'>
                                                                <CheckBoxField id={"terminal"} label={`${T_PREFIX}registeredUsersTable.terminals`} onChange={(event) => this.setState({ ...this.state, roleOperator: "terminals" })} checked={this.state.roleOperator === "terminals"}></CheckBoxField>
                                                            </div><div className='mt-3 d-block'>
                                                                <CheckBoxField id={"clientDocument"} label={merchantLabel} onChange={(event) => this.setState({ ...this.state, roleOperator: "clientDocument" })} checked={this.state.roleOperator === "clientDocument"}></CheckBoxField>
                                                            </div></>
                                                )}
                                                    {this.state.userForm.roles.some(role => role.value === 'Minorista') && (
                                                        <><div className='mt-3 d-block'>
                                                                <CheckBoxField id={"clientDocument"} label={merchantLabel} onChange={(event) => this.setState({ ...this.state, roleOperator: "clientDocument" })} checked={this.state.roleOperator === "clientDocument"}></CheckBoxField>
                                                            </div></>
                                                )}
                                            </div>
                                            <div className="col-md-6 terminal-input">
                                            {   this.state.roleOperator == "none" || this.state.roleOperator == "terminals" &&
                                                this.state.userForm.roles.some(role => role.value === 'Operator') && (
                                                    <TypeaheadField
                                                        attr="terminals"
                                                        label={`${T_PREFIX}modalEditUser.terminals`}
                                                        options={this.state.terminalOptionList}
                                                    ></TypeaheadField>
                                                )}
                                            </div>
                                            <div className="col-md-6 clientDocument-input">
                                            {   this.state.roleOperator == "none" || this.state.roleOperator == "clientDocument" &&
                                                this.state.userForm.roles.some(role => role.value === 'Operator'|| role.value === 'Minorista') && (
                                                <TypeaheadField
                                                    attr="clientDocuments"
                                                    label={merchantLabel}
                                                    options={this.state.clientDocumentOptionList}
                                                ></TypeaheadField>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                                <label className="custom-control mt-4 text-danger">
                                    <span>{t(this.state.validationError)}</span>
                                </label>
                                </>
                            </Card>
                            <div className="d-flex justify-content-end">    
                                <Button
                                    className='m-3 me-0 '
                                    onClick={this.closeEditUserModal.bind(this)}
                                    label="cancel"
                                    color="white"
                                    noBlock
                                ></Button>
                                <SubmitButton
                                    className='m-3'
                                    onClick={this.saveUser.bind(this)}
                                    label={`${T_PREFIX}${createMode ? 'createUser' : "editUser"}`}
                                    disabled={Boolean(this.state.validationError)}
                                    noBlock
                                ></SubmitButton>
                            </div>
                        </div>
                    </div>
                    </>
                </Page>
            )
        };
    

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                { this.state.showEditUserModal ? (
                this.renderCreateAndUpdateUserModal()
                ) : (
                    <Page>
                <>
                    <div className="row">
                        <h2>{t(`${T_PREFIX}title`)}</h2>
                        <div className="col-lg-12">
                            <>
                                <FormFilter 
                                    model={[this.state.filters, this.setFilters.bind(this)]} 
                                    onSubmit={this.getUsers}
                                    clear={()=> this.setState({...this.state, filters:{ email: "", roles: [] }})}
                                >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <EmailField attr="email" label="email" ></EmailField>
                                        </div>
                                        <div className="col-md-4">
                                            <TypeaheadField attr="roles" label="Roles" options={this.state.roleOptionList} ></TypeaheadField>
                                        </div>
                                    </div>
                                </FormFilter>
                                <div className="row">
                                    <div className="col-md-9"></div>
                                    <div className="col-md-3">
                                        <Button onClick={this.cleanEditFormFields.bind(this)} className="mt-0 mb-4" >
                                            <p className='mb-0'>
                                                <span className='me-3'>{t(`${T_PREFIX}addUser`)}</span>
                                                <IconAddSVG></IconAddSVG> 
                                            </p>
                                        </Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <CustomTable
                                            rowObjects={this.state.users}
                                            columnDefinitions={this.columnDefinitions}
                                            config={{
                                                paginator: {
                                                    mainPage: this.state.mainPage,
                                                    modifyMainPage: (value: number) => {
                                                        this.setState({
                                                            mainPage: this.state.mainPage + value
                                                        }, () => this.getUsers())
                                                    },
                                                    pageRequest: this.state.pageRequest
                                                }
                                            }}
                                        ></CustomTable>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                    {/* DELETE USER MODAL */}
                    { this.renderDeleteUserModal() }
                </>
            </Page>
                )}
            </React.Fragment>     
        );
    };
}

export default withTranslation()(UsersManagement);