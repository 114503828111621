import React, {Component, RefObject} from "react";
import { TFunction, withTranslation } from "react-i18next";
import { ColumnDefinition, actionColumnDefinition, currencyColumnDefinition, dateColumnDefinition, numericColumnDefinition, textColumnDefinition } from "../table/ColumnDefinition";
import { DateObject } from "react-multi-date-picker";
import {IChargeBack, IDailySettlements} from "../../models/ISettlementData";
import {IChargeBacksRequest, IDownloadDailyESettlement} from "../../requests/ISettlementsRequest";
import Page from "../containers/page";
import ErrorsView from "../ErrorsView";
import NavigationTabLinksForSettlements from "./NavigationTabLinksForSettlements";
import FormFilter from "../containers/form-filter";
import DateField from "../controls/fields/dateField";
import SelectField from "../controls/fields/selectField";
import NumericField from "../controls/fields/numericField";
import CustomTable, { TableModeEnum } from "../table/CustomTable";
import Button from "../controls/buttons/button";
import { ReactComponent as IconDownloadSVG } from "../../../node_modules/itd-common/assets/icons/download.svg";
import { ReactComponent as IconPrintSVG } from "../../../node_modules/itd-common/assets/icons/print.svg";
import { FiscalSettlementService } from "../../services/FiscalSettlementService";
import { DateHelper } from "../../helpers/DateHelper";
import { UtilService } from "../../services/UtilService";
import {Option} from "../../models/Option";
import {WebCache} from "../../services/WebCache";
import {toast} from "react-toastify";
import {AxiosResponse} from "axios";
import CustomReCAPTCHA from "../CustomCaptcha";
import './Settlement.scss'
import TextField from "../controls/fields/textField";
import {UserService} from "../../services/UserService";
import i18next from "i18next";
import ReactToPrint from "react-to-print";
import {CurrencyHelper} from "../../helpers/CurrencyHelper";
import Tooltip from "../Tooltip";
import { ReactComponent as IconInfoSVG } from "../../../node_modules/itd-common/assets/icons/alert-information.svg";

interface IProps {
    t: TFunction
}

interface IFilters {
    dateRange: Array<DateObject>,
    voucherNumber: number,
    type: string,
    taxPayer: string,
    amount:string,
    currency: string
}

interface IState {
    mainPage: number,
    pageRequest: IChargeBacksRequest,
    settlements: IChargeBack[],
    errors: string[],
    filters: IFilters,
    currencyOptions: Array<HTMLOptionElement>,
    typeOptions: Array<HTMLOptionElement>,
    allFiscalDocuments: Array<Option>,
    isCaptchaVerified:boolean
}

const T_PREFIX: string = 'pages.settlementChargeBacks.';

class SettlementChargeBacks extends Component<IProps, IState> {
    private columnDefinitions: Array<ColumnDefinition> = [];
    private componentRef:RefObject<any> = React.createRef();
    constructor(props: IProps) {
        super(props);
        
        const currencyOptions: Array<HTMLOptionElement> = UtilService.getCurrencyOptions();
        const currencyFilter: string = currencyOptions[0].value;
        const typeOptions: Array<HTMLOptionElement> = this.props.t(`${T_PREFIX}tipos`, { returnObjects: true });
        const type: string = typeOptions[0].value;
        
        const currency: number = 858;
        this.columnDefinitions = [
            dateColumnDefinition({ key: 'presentationDate', label: `${T_PREFIX}columns.paymentDate`, header: true, mapValue: (settlement: IChargeBack) => {
                const date = new Date(settlement.presentationDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = String(date.getFullYear());

                return `${day}/${month}/${year}`;
            } }),
            textColumnDefinition({ key: 'currencyDesc', label: `${T_PREFIX}columns.currencyDesc`, header: true }),
            numericColumnDefinition({ key: 'grossAmount', label: `${T_PREFIX}columns.grossAmount`, header: true }),
            numericColumnDefinition({ key: 'netAmount', label: `${T_PREFIX}columns.netAmount`, header: true }),
            textColumnDefinition({ key: 'voucherNumber', label: `${T_PREFIX}columns.voucherNumber`, header: true }),
            textColumnDefinition({ key: 'last4NumCard', label: `${T_PREFIX}columns.last4NumCard`, header: true }),
            textColumnDefinition({ key: 'detail', label: `${T_PREFIX}columns.detail`, header: true }),
            numericColumnDefinition({ key: 'settlmentNumber', label: `${T_PREFIX}columns.settlmentNumber`, header: true }),
            actionColumnDefinition({ key: 'edit', label: `${T_PREFIX}columns.download`, header: true, action: { function: (rowObject: IChargeBack) => {
                this.getDowloadESettlement(rowObject.settlmentNumber,rowObject.settlmentNumber,rowObject.merchantNumber,rowObject.productCode,rowObject.productDesc);
            }, icon: <IconDownloadSVG className="icon-download"></IconDownloadSVG>} })
        ];

        this.state = {
            mainPage: 0,
            pageRequest: {
                CouponNumber: '',
                From: '',
                To: '',
                Type: '',
                Amount:'',
                Currency: '',
                MerchantDocument: "",
                Skip: 0,
                Take: 0
            },
            settlements: [] as IChargeBack[],
            errors: [],
            filters: {
                amount:'',
                dateRange: [],
                voucherNumber: 0,
                type,
                taxPayer: WebCache.getAllMerchantDocuments(false,true)[0]?.key+"",
                currency: currencyFilter
            },
            allFiscalDocuments: WebCache.getAllMerchantDocuments(false,true),
            currencyOptions,
            typeOptions,
            isCaptchaVerified: false
        };
    }

    getDowloadESettlement = (fileName: string, settlmentNumber: string, merchantNumber: string,productCode:string,productDesc:string) => {
        if (this.state.isCaptchaVerified) {
            toast.info(this.props.t("settlementsDownloadFileNotification"));
            FiscalSettlementService.getDowloadDailyESettlement(this.getDownloadDailyESettlementRequest(fileName, settlmentNumber, merchantNumber,productCode,productDesc))
                .then((response: AxiosResponse<Blob>) => {
                })
                .catch((error) => {
                    this.setState({  errors: ["Error obteniendo datos del servidor"] });
                    console.log('error:', error);
                });
        } else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        }
    };

    getDownloadDailyESettlementRequest = (fileName: string, settlmentNumber: string, merchantNumber: string,productCode:string,productDesc:string): IDownloadDailyESettlement => {
        const result: IDownloadDailyESettlement = {
            FileName: fileName,
            SettlementNumber: parseInt(settlmentNumber),
            FiscalNumber: this.state.filters.taxPayer,
            MerchantNumber:merchantNumber,
            ProductCode:productCode,
            ProductDesc:productDesc
        };
        return result;
    }

    getRequest = (all: boolean): IChargeBacksRequest => {
        const request: IChargeBacksRequest = {
            CouponNumber: this.state.filters.voucherNumber>0?this.state.filters.voucherNumber+"":"",
            From: DateHelper.dateObjectToString(this.state.filters.dateRange[0]),
            To: DateHelper.dateObjectToString(this.state.filters.dateRange[1]),
            Type: this.state.filters.type,
            Amount:this.state.filters.amount,
            Currency: this.state.filters.currency,
            MerchantDocument: this.state.filters.taxPayer,
            Skip: all ? 0 : (this.state.pageRequest.Skip),
            Take: all ? 0 : (this.state.pageRequest.Take),
        };
        return request;
    }

    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    downloadGrid = () => {
        let fileName="Ajusted.csv";
        UserService.getCsvOnline(this.columnDefinitions.filter(x=>x.action==null).map(x=>i18next.t(x.label+"")).join(";")+"\r\n"+
            // @ts-ignore
            this.state.settlements.map(y=>this.columnDefinitions.map(x=>y[x.key]).join(";")).join("\r\n"), fileName).then((response: AxiosResponse<any>) => {
            //const outputFilename = `TerminalsReport${Date.now()}.xlsx`;
            // @ts-ignore
            /*let outputFilename = response.headers['content-disposition']
                .split(';')
                .find((n: any) => n.includes('filename='))
                .replace('filename=', '')
                .trim();*/
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
        }).catch(error => {
            console.log('error:', error);
        });
    }

    getChargeBacks = () => {
        FiscalSettlementService.getChargeBacks(this.getRequest(false))
        .then((response1) => {
            return response1.data;
        })
        .then((response: IChargeBack[]) => {
            this.setState({...this.state,
                settlements: response,
                errors: []
            });
        }).catch(error => {
            this.setState({...this.state,
                errors: ["Error obteniendo datos del servidor"],
                settlements: [] as IChargeBack[]
            })
        });
    }

    searchFilter = () => {
        this.setState({
            ...this.state, mainPage: 0
        }, () => {
            this.getChargeBacks();
        });
    }

    setFilters(filters: IFilters) {
        this.setState({ ...this.state, filters });
    }

    clearFilter = () => {
        this.setFilters({} as IFilters);
    }

    renderTotalNetAmounts = (chargeBacks: IChargeBack[]) => {
        const { t } = this.props;

        // Neto total para la moneda Pesos uruguayos
        let totalNetAmount =
            Intl.NumberFormat('es-UY', { style: 'currency', currency: CurrencyHelper.getCurrencySymbol("858"), maximumFractionDigits: 0 }).format(
                chargeBacks.reduce((acc, dailySettlement) => {
                    return dailySettlement.currencyCode != '840' ? acc + dailySettlement.netAmount : acc;
                }, 0)
            );
        // Neto total para la moneda de USD
        let totalNetAmountUSD =
            Intl.NumberFormat('es-UY', { style: 'currency', currency: CurrencyHelper.getCurrencySymbol("840"), maximumFractionDigits: 0 }).format(
                chargeBacks.reduce((acc, dailySettlement) => {
                    return dailySettlement.currencyCode === '840' ? acc + dailySettlement.netAmount : acc;
                }, 0)
            );

        return (
            WebCache.getCurrentUser()?.countryId == 2 ?
            <p className='ms-2 d-inline-block net-total'>
                {t(`common.business.netTotal`)}:{totalNetAmount} | {totalNetAmountUSD}
            </p> : 
            <p className='ms-2 d-inline-block net-total'>
                {t(`common.business.netTotal`)}:{totalNetAmount}
            </p>
        );
    }

    render() {
        const { t } = this.props;
        const currentUser = WebCache.getCurrentUser()?.countryId;
        let currency;
        let taxPayerId

        if (currentUser !== undefined && currentUser === 2) {
            currency = <SelectField attr='currency' label='currency' options={this.state.currencyOptions} ></SelectField>
            taxPayerId=`${T_PREFIX}merchantURU`
        } else {
            currency = ''
            taxPayerId=`${T_PREFIX}merchantARG`
        }		

        return (
            <Page>
                <>
                    <h2>{t(`settlements`)}</h2>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <ErrorsView errors={this.state.errors}/>
                        </div>
                    </div>
                    <div className="row" ref={this.componentRef}>
                        <div className="col-lg-12">
                            <div className="col-lg-12 mt-2">
                                <NavigationTabLinksForSettlements></NavigationTabLinksForSettlements>
                            </div>
                            <FormFilter
                                onSubmit={this.searchFilter}
                                clear={this.clearFilter}
                                model={[this.state.filters, this.setFilters.bind(this)]}>
                                <>
                                    <div className="row mt-2">
                                        <div className="col-md-4">
                                            <DateField attr="dateRange" label="dateRange" range></DateField>
                                        </div>
                                        <div className="col-md-4">
                                            <TextField attr="voucherNumber"
                                                    label={`${T_PREFIX}voucherNumber`}></TextField>
                                        </div>
                                        <div className="col-md-4">
                                            <SelectField
                                                attr='taxPayer'
                                                label={taxPayerId}
                                                options={this.state.allFiscalDocuments}
                                            ></SelectField>
                                        </div>
                                        <div className="col-md-4">
                                            <SelectField attr='type' label='type'
                                                        options={this.state.typeOptions}></SelectField>
                                        </div>
                                        <div className="col-md-4">
                                            {currency}
                                        </div>
                                    </div>
                                </>
                            </FormFilter>
                            <nav className="nav mt-3">
                                <span>
                                <Button color="transparent" className="border-0 mt-0"
                                        onClick={() => this.downloadGrid()} noBlock>
                                    <>
                                        <IconDownloadSVG></IconDownloadSVG>
                                        <span className='ms-2 text-dark'>{t(`common.action.downloadCsv`)}</span>
                                    </>
                                </Button>
                                <ReactToPrint
                                    trigger={() => <Button color="transparent" className="border-0 mt-0" noBlock>
                                        <>
                                            <IconPrintSVG></IconPrintSVG>
                                            <span className='ms-2 text-dark'>{t(`common.action.print`)}</span>
                                        </>
                                    </Button>}
                                    content={() => this.componentRef.current}
                                />
                                </span>
                                <span className="navbar-text ms-auto col-md-3 fs-5">
                                        <Tooltip message={t(`${T_PREFIX}netTotalInfoTooltip`)}>
                                            <IconInfoSVG className='d-inline-block'></IconInfoSVG>
                                        </Tooltip>
                                    {this.renderTotalNetAmounts(this.state.settlements)}
                                    </span>
                            </nav>
                            <div className='row'>
                                <div className="col-md-12">
                                    <CustomTable
                                        rowObjects={this.state.settlements}
                                        columnDefinitions={this.columnDefinitions}
                                        config={{
                                            mode: TableModeEnum.LIST,
                                            paginator: {
                                                mainPage: this.state.mainPage,
                                                modifyMainPage: (value: number) => {
                                                    this.setState({
                                                        mainPage: this.state.mainPage + value
                                                    }, () => this.getChargeBacks())
                                                },
                                                pageRequest: this.state.pageRequest
                                            }
                                        }}
                                    ></CustomTable>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                </div>
                                <div className="col-md-6">
                                    <CustomReCAPTCHA onVerify={this.handleCaptchaVerification}></CustomReCAPTCHA>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Page>
        );
    }
}

export default withTranslation() (SettlementChargeBacks);