import axios from 'axios';
import { WebCache } from "./WebCache";

import Swal from 'sweetalert2';
export const axiosApiInstance = axios.create();

const exlusionFromSpinner = ["FileExporter/getAllTasks", "queryTransaction","Settlement/getAllPayerBanksAndProducts"]

export class HttpService {

    public static startSpinner() {
        WebCache.disableTasks();
        const divLoading = document.createElement("div")
        divLoading.id = "divLoading"
        divLoading.className = 'overlay';

        const divDimmer = document.createElement("div");
        divDimmer.className = "centerOverlay";

        const divBonunce1 = document.createElement("div");
        divBonunce1.className = "double-bounce1";

        const divBonunce2 = document.createElement("div");
        divBonunce2.className = "double-bounce2";

        divDimmer.appendChild(divBonunce1);
        divDimmer.appendChild(divBonunce2);
        divLoading.appendChild(divDimmer);
        document.body.appendChild(divLoading);
    }

    public static stopSpinner() {
        WebCache.enableTasks();
        const div = document.getElementById('divLoading')?.remove();
    }

    public static setupInterceptors() {
        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {

                const url = config.url + "";
                var showLoader = true;
                for (var i in exlusionFromSpinner) {
                    showLoader = url.indexOf(exlusionFromSpinner[i]) < 0;
                    if (!showLoader)
                        break;
                }
                if (showLoader)
                    HttpService.startSpinner();

                if (url.indexOf("/Users/authenticate") >= 0 || url.indexOf("/Users/makeAssertion") >= 0) {
                    WebCache.clearCache();
                }
                const userStr = localStorage.getItem("user");
                let user = null;
                if (userStr)
                    user = JSON.parse(userStr);
                if (user && user.token) {
                    config.headers = {
                        'Authorization': `Bearer ${user.token}`,
                        'Accept': 'application/json'
                    }
                } else {

                }
                return config;
            },
            error => {
                Promise.reject(error)
            });

        axiosApiInstance.interceptors.response.use((response) => {
            HttpService.stopSpinner();
            const url = response?.config.url + "";
            if (url.indexOf("/Users/authenticate") >= 0 || url.indexOf("/Users/makeAssertion") >= 0||url.indexOf("/Users/moveToCompany")>= 0 ) {
                WebCache.clearCache();
                // const userStr =
                if (response.data.success)
                    localStorage.setItem("user", JSON.stringify(response.data.data));
            }
            return response
        }, async function (error) {
            HttpService.stopSpinner();
            if (error.response.status === 401) {
                WebCache.clearCache();
                
                const splittedHref: Array<string> = window.location.href.split('/');
                const currentURL: string = `/${splittedHref[splittedHref.length - 1]}`;

                let authorizedUrls = ['/forgot-password', '/reset-password'];

                if (!authorizedUrls.includes(currentURL)) {
                    WebCache.clearCache();
                    window.location.href = "/#/login";
                }
            }else if (error.response.status === 403) {
                Swal.fire({
                    title: "Permisos",
                    html: "No tiene permisos suficientes",
                    confirmButtonColor: '#f60',
                    timer: 6000,
                    timerProgressBar: true,
                    icon:"warning"
                });
            }
            return Promise.reject(error);
        });
    }


}
