import { AxiosResponse } from 'axios';
import React, { ReactElement } from 'react';
import { DateObject } from "react-multi-date-picker"
import { TransactionService } from '../../services/TransactionService';
import { ITransaction, ITransactionWebModelRequest } from "../../requests/ITransactionWebModelRequest";
import { TFunction, withTranslation } from 'react-i18next';
import "../transactions/TransactionReport.css";
import { WebCache } from "../../services/WebCache";
import { IIssuer } from "../../models/IIssuer";
import ErrorsView from '../ErrorsView';
import TextField from '../controls/fields/textField';
import NumericField from '../controls/fields/numericField';
import SelectField from '../controls/fields/selectField';
import DateField from '../controls/fields/dateField';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import Page from '../containers/page'
import FormFilter from '../containers/form-filter';
import Form from "../containers/form";
import {DateHelper } from '../../helpers/DateHelper';
import NavigationTabLinksForCharges from './NavigationTabLinksForCharges';
import { t } from 'i18next';
import { ColumnDefinition, actionColumnDefinition, currencyColumnDefinition, dateColumnDefinition, textColumnDefinition } from '../table/ColumnDefinition';
import CustomTable, { TableModeEnum } from '../table/CustomTable';
import { IPageRequest } from '../../models/IPageRequest';
import {Modal } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import LabelMessage from '../LabelMessage';
import SubmitButton from '../controls/buttons/submitButton';
import { TOTPService } from '../../services/TOTPService';
import { toast } from 'react-toastify';
import { AuthenticateTotpDevice } from '../../requests/ITOTPRequest';
import { MconboardingService } from '../../services/Mconboarding';
import { IIssuerTotalDto } from '../../models/IIssuerTotalDto';
import { CurrencyHelper } from '../../helpers/CurrencyHelper';
import TotalTransactions from './TotalTransactions';
import { UtilService } from '../../services/UtilService';
import CustomReCAPTCHA from '../CustomCaptcha';
import { ReactComponent as LogoSVG } from "../../../node_modules/itd-common/assets/images/brand/logo.svg";
import Button from '../controls/buttons/button';
interface IProps {
    t: TFunction
}
interface CodeFormType {
    otpCode:string
}
interface ActionResult{
    show:boolean,
    type?:string
}
interface IState {
    fromDate: DateObject,
    toDate: DateObject,
    activePage: number,
    mainPage: number,
    pages: number[],
    transactions: ITransaction[],
    columns: ColumnDefinition[],
    errors: string[],
    operationTypeFilter: string,
    amountFilter: string,
    merchantFilter: string,
    paymentmodeFilter: string,
    muidFilter: string
    issuerFilter: string,
    currentIssuers: IIssuer[],
    terminalFilter: string,
    userNameFilter:string,   
    pageRequest: IPageRequest,
    tableMode: TableModeEnum,
    showModalAmount: boolean,
    showModalAuth:boolean,
    showModalConfirmation:boolean,
    issuersByTotal: IIssuerTotalDto[],
    tranId:string,
    selectedTransactions: ITransaction | null,
    mfaDevices: any [],
    isOTPError:boolean,
    enteredAmount:number,
    isAmountValid:boolean,
    otpCodeForm:CodeFormType,
    rowDetail: { title: string, total: string, data: Array<{ prop: string, value: string }> } | null,
    isCaptchaVerified: boolean;
}

const pageSize = 13;
const pageQuantity = 3
const defaultPages = [1, 2, 3]
let doQuery = false;
const T_PREFIX: string = 'pages.transactionsQR.';
const allowedOperationTypes = [ 'all','C','D'];
type BuildTotalDetailOptions = { object: any, title: string, accumulateTotal: boolean };
class TransfersReturnReport extends React.Component<IProps, IState> {
    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);
    //private columnDefinitions: Array <ColumnDefinition> = [] 

    componentDidMount() {
        if (doQuery) {
            doQuery = false;
            this.getTransactions();
        }
    }

    constructor(props: IProps) {        
        super(props);
        let issuerFilter = "";
        let terminalFilter = "";
        let operationTypeFilter = "";
        let userNameFilter = "";
        let [ fromDate, toDate ] = DateHelper.buildDateRange();

        this.state = {
            fromDate: fromDate,
            toDate: toDate,
            activePage: 1,
            mainPage: 0,
            pages: defaultPages,
            columns: [
            ],
            transactions: [],
            errors: [],
            operationTypeFilter: operationTypeFilter,
            issuerFilter: issuerFilter,
            amountFilter: "",
            terminalFilter: terminalFilter,
            merchantFilter: "",
            paymentmodeFilter:"",
            muidFilter: "",
            currentIssuers: WebCache.getAllIssuers(),
            userNameFilter:userNameFilter,
            tableMode: TableModeEnum.LIST,
            pageRequest: {
                Skip: 0,
                Take: 0
            },
            showModalAmount:false,
            showModalAuth:false,
            showModalConfirmation:false,
            issuersByTotal: [],
            tranId:"",
            selectedTransactions: null,
            mfaDevices: [],
            isOTPError:false,
            enteredAmount:0,
            isAmountValid:true,
            otpCodeForm: {
                otpCode:''
            },
            rowDetail: null,
            isCaptchaVerified: false
        }     
        this.processAction =this.processAction.bind(this);
    }

    getTransactionRequest = (all: boolean) => {
        let from_year: number;
        let from_month: number;
        let from_day: number;
        let to_year: number;
        let to_month: number;
        let to_day: number;
        from_year = this.state.fromDate.year;
        from_month = this.state.fromDate.month.number;
        from_day = this.state.fromDate.day;
        to_year = this.state.toDate.year;
        to_month = this.state.toDate.month.number;
        to_day = this.state.toDate.day;

        const tranWebModel: ITransactionWebModelRequest = {
            From: new Date(from_year, from_month - 1,
                from_day,
                this.state.fromDate.hour - 3,
                this.state.fromDate.minute).toJSON(),
            To: new Date(to_year,
                to_month - 1,
                to_day,
                this.state.toDate.hour - 3,
                this.state.toDate.minute).toJSON(),
            OperationType: (this.state.operationTypeFilter == null || this.state.operationTypeFilter == "" || this.state.operationTypeFilter.toLowerCase() == "all") ? "" : this.state.operationTypeFilter,
            Amount: (this.state.amountFilter == null || this.state.amountFilter == "") ? "" : this.state.amountFilter,
            MerchantCode: (this.state.merchantFilter == null || this.state.merchantFilter == "") ? "" : this.state.merchantFilter,
            Issuer: (this.state.issuerFilter == null || this.state.issuerFilter == "0000") ? "" : this.state.issuerFilter,
            Terminals: (this.state.terminalFilter == null || this.state.terminalFilter == "") ? [] : [this.state.terminalFilter], 
            PaymentMode: (this.state.paymentmodeFilter == null || this.state.paymentmodeFilter == "") ? "" : this.state.paymentmodeFilter,
            MuId: (this.state.muidFilter == null || this.state.muidFilter == "") ? "" : this.state.muidFilter,
            UserId: (this.state.userNameFilter == null || this.state.userNameFilter == "") ? "" : this.state.userNameFilter,
            Skip: all ? 0 : (this.state.mainPage * pageSize * pageQuantity),
            Take: all ? 0 : ((pageSize * pageQuantity) + 1),
        };
        return tranWebModel;
    }

    getTransactions = () => {
        TransactionService.getQrTransactionsAll(this.getTransactionRequest(false)).then((response: AxiosResponse<ITransaction[]>) => {
            if (response == null || response.data == null || response.data.length == 0) {
                this.setState({
                    transactions: [],
                    columns: [],
                    errors: []
                })
                return;
            }
            this.setState({
                transactions: response.data,
                errors: [],
            });
        }).catch(error => {
            this.setState({
                errors: error.response.data
            });
            this.setState({
                transactions: []
            })
            console.log('error:', error);
        }
        );
    }

    shouldShowAction (transaction:ITransaction): ActionResult {

        const isWithin31Days = (dateString: string): boolean => {
            const [datePart,timePart]= dateString.split (' ');
            const [day, month, year]= datePart.split('/').map(Number);
            const [hours,minutes,seconds] = timePart.split (':').map(Number);
            const transactionDate = new Date(year, month-1,day,hours,minutes,seconds);
            const currentDate = new Date ();
            const differenceInDays = Math.floor((currentDate.getTime() - transactionDate.getTime()) / (1000 * 3600 * 24));
            return differenceInDays < 31 
        } 

        const {transactionType,state,authDateTime,refundBalance} = transaction
        const refBalance = refundBalance ?  parseFloat(refundBalance) : NaN
        const isRefBalanceToAmount = refundBalance === ""
        const isDateOutSide31Days=  isWithin31Days(authDateTime)
        //console.log ('transactionTtype',transactionType,'state',state, 'authDate Time', authDateTime)

        if  ((transactionType === "C")  && ( state == 2)  &&  (isDateOutSide31Days) && (refBalance>0)){
            return {show: true, type: 'defaultAction'}
        }
        
        if((isRefBalanceToAmount) && (isDateOutSide31Days) ){
            return {show: true, type: 'refundAction'}
        }

        if  (!isDateOutSide31Days) {
            return {show:true,type:'noRefund'}
        }
        return  {show:false,type:undefined}
    }

    updateColumnDefinition (){
        const staticColumns: ColumnDefinition[]= [
            dateColumnDefinition({ key: 'authDateTime', label: 'authDateTime', header: true, percentageWidth:30 , mapValue:(fechaOp:ITransaction)=>{
                const authDateTime: string = fechaOp.authDateTime
                return UtilService.getDDMMyyHHmmssFormatFromString(authDateTime);
            }}),
            textColumnDefinition({ key: 'transactionType', label: 'transactionType', header: true, percentageWidth:10, mapValue:(tipo:ITransaction) =>{
                const transactionType:string = tipo.transactionType
                return (t("transactionType-"+transactionType)+"")
            } }),
            textColumnDefinition({ key: 'state', label: 'state', header: true, percentageWidth:10, mapValue:(estado:ITransaction) =>{
                const state:number = estado.state
                return (t("state-"+state)+"")
            } }),
            textColumnDefinition({ key: 'issuer', label: 'issuer', header: true, percentageWidth:10, mapValue:(imagen:ITransaction)=>{
                const issuer: string = imagen.issuer;
                return <img src={UtilService.getImageTag((t("issuer-"+issuer)+"").toUpperCase())} alt=''></img>
            }}),
            currencyColumnDefinition({ key: 'totalAmountForReport', label: 'totalAmountForReport', header: true, percentageWidth:10,columnDefinitionKey: 'currency' }),
            textColumnDefinition({ key: 'muId', label: 'muId', header: true, percentageWidth:10 }),
            actionColumnDefinition({key:"Gestion", label:"Gestion",header:true,action:{
                function:(transaction:ITransaction) =>{
                const action = this.shouldShowAction(transaction)
                    if (!action.show){
                        return null;
                    }
                    switch(action.type){
                        case 'defaultAction':
                        return this.processAction(transaction)
                        case 'refundAction':
                        return null
                        case 'noRefund':
                        return null
                        default:
                        return null
                    }
                },
                icon:(transaction:ITransaction) =>{
                    const action = this.shouldShowAction(transaction)
                    if (!action.show){
                        return null;
                    }
                    switch(action.type){
                        case 'defaultAction':
                        return <div className='containerIconRefund'> <i className='fa-solid fa-file-invoice-dollar fa-lg iconRefund '><p className='textIconRefund'>Devolver Dinero</p></i></div>
                        case 'refundAction':
                        return <div className='containerIconRefund'> <i className='fa-solid fa-check iconRefund'><p className='textIconRefund'>Devoluciones realizadas con éxito</p></i></div>
                        case 'noRefund':
                        return <div className='containerIconRefund'> <i className='fa-solid fa-x iconRefund'><p className='textIconRefund'>No es posible realizar devoluciones</p></i></div>
                        default:
                        return ""
                    }
                }}})
            ,
            textColumnDefinition({ key: 'merchant', label: 'merchantNro', header: false, percentageWidth: 20 }),
            textColumnDefinition({ key: 'terminal', label: 'terminal', header: false, percentageWidth: 20, mapValue:(t:ITransaction)=>{
                const terminal:string = t.terminal
                if (terminal.toString().includes("B24_")) {                                                        
                    return terminal.toString().substring(4);
                } else {
                    return terminal + "";                                                
                }
            } }),            
            currencyColumnDefinition({ key: 'refundBalance', label: 'refundBalance', header: false, percentageWidth: 20,columnDefinitionKey: 'currency' }),
            currencyColumnDefinition({ key: 'relatedTransactions', label: 'relatedTransactions',header:false,percentageWidth: 20, action: { function: this.showTotalRefundAction.bind(this), icon:'fa-solid fa-magnifying-glass'},columnDefinitionKey: 'currency' }),
            textColumnDefinition({ key: 'userId', label:'userName',header:false,percentageWidth:10})
        ]
        return staticColumns
    }

    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    getTransactionsToExcel = () => {
        if (this.state.isCaptchaVerified) {
            TransactionService.getAllToExcel(this.getTransactionRequest(true))
                .then((response: AxiosResponse<Blob>) => {
                })
                .catch((error) => {
                    this.setState({ errors: error.response.data });
                    console.log('error:', error);
                });
        } else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        }
    };
    

    showTotalRefund: (rowObject: ITransaction, detailOptions: BuildTotalDetailOptions) => void = (rowObject: ITransaction, detailOptions: BuildTotalDetailOptions): void => {       
        if (detailOptions) {
            const currencySymbol:string = CurrencyHelper.getCurrencySymbol(rowObject.currency);
            this.setState({
                ...this.state,
                rowDetail: TransactionService.buildTotalDetail(detailOptions.object, detailOptions.title, currencySymbol, detailOptions.accumulateTotal),
            });
        }
    };

    showTotalRefundAction: (rowObject: ITransaction) => void = (rowObject: ITransaction): void => {
        var o:any={};
        for(var i in rowObject.relatedTransactions){
            o[rowObject.relatedTransactions[i].transactionReference]= Number.parseInt(rowObject.relatedTransactions[i].amount)/100;
        }
        this.showTotalRefund(rowObject, { object: o, title: "relatedTransactions", accumulateTotal: true });
    }; 

    getFromBuscar = () => {
        this.setState({
            ...this.state,
            activePage: 1, 
            pages: defaultPages,
            mainPage: 0
        }, ()=>{
            this.getTransactions();})        
    }
    openAmountModal = () =>{
        this.setState({ showModalAmount: true})
    }

    closeAmountModal = () => {
        this.setState({showModalAmount:false})
    }

    openAuthModal = () =>{
        this.setState({showModalAuth:true})
    }

    closeAuthModal = ()=>{
        this.setState({showModalAuth:false,otpCodeForm:{otpCode:""}})
    }

    openConfirmationModal = () =>{
        this.setState ({showModalConfirmation:true})
    }

    closeConfirmationModal = ()=>{
        this.setState({showModalConfirmation:false})
    }

    processAction = ( transaction: ITransaction) => {
        this.openAmountModal();
        this.setState({selectedTransactions:transaction})
    };

    // Lógica para la solicitud del MFA
    handleMfaConfirmation = async () => {
        this.closeAmountModal();
        this.openAuthModal();
        await TOTPService.getMfaDevices()
            .then((response) => {
                const data = response.data.deviceName;
                if (Array.isArray(data)) {
                    this.setState({mfaDevices:data})
                } else {
                    toast.error("Error: Respuesta de dispositivos MFA incorrecta");
                }
            })
            .catch((error) => {
                console.error("Error en la solicitud de dispositivos MFA:", error);
            });
    };

    handleSubmitOTP = (): void =>{  
        const request: AuthenticateTotpDevice = {
                deviceName: this.state.mfaDevices[0],
                otpCode: this.state.otpCodeForm.otpCode,
            };
            TOTPService.authenticateTotpDevice(request)
                .then((response) => {
                    const data = response.data;
                    if (data.success) {
                        this.openConfirmationModal(); 
                        this.closeAuthModal()
                    } else {
                        this.setState ({isOTPError:true}) 
                    }
                })
                .catch((error) => {
                    console.error("Error en la solicitud de autenticación OTP:", error);
                });
    } 
    
    // Lógica para aceptar o no la solicitud de devolución
    handleConfirmation = (isAccepted: boolean) => {
        const request = {
            terminal: this.state.selectedTransactions?.terminal || '',
            amount: this.state.enteredAmount.toString(),
            currency: this.state.selectedTransactions?.currency || '',
            originalMuId: this.state.selectedTransactions?.muId || '',
            originalTerminal: this.state.selectedTransactions?.terminal || '',
            merchantCode: this.state.selectedTransactions?.merchant || '',
            originalIssuer: this.state.selectedTransactions?.issuer || ''
        };
        
        if (isAccepted) {
            MconboardingService.insertQrRefund(request).then(() => {
                toast.success ("Devolución realizada con éxito")
                this.refreshTransactions();
            }).catch ((error) => {
                console.error('Error al enviar la solicitud de devolución:', error);
                toast.error ('Error al realizar la devolución')
                this.closeConfirmationModal()
            })
        } else {
            toast.error ('Solicitud de devolución cancelada')
            console.log ('Error servicio')
        }
    }

    //refreshTransactions --> Lógica para refrescar la busqueda y que se muestren todas las transacción con las devoluciones una vez que se hayan realizado
    refreshTransactions = () => {
        this.setState({
            ...this.state, activePage: 1, pages: defaultPages,
            mainPage: 0,
            showModalConfirmation:false
        }, this.getTransactions);
    }
    
    buildIssuerSelectOptions() {
        let options = this.optionMapper.buildSelectOptionsFromAnArrayWithDifferentInterface(this.state.currentIssuers, 'id', 'issuerName');
        const allIndex = options.findIndex(option => option.value === '0000');

        if (allIndex !== -1) {
            const todosOption = options.splice(allIndex, 1)[0]; 
            options = [todosOption, ...options]; 
        }
    
        options.sort((a, b) => {
            if (a.label === 'Todos') return -1; 
            if (b.label === 'Todos') return 1; 
            return a.label.localeCompare(b.label);
        });
    
        return options;
    } 

    buildOperationTypeSelectOptions() {
        const translationKeys = allowedOperationTypes.map((type) => {
            if (type === 'C') {
                return `${T_PREFIX}operationType-CPT`;
            }
            return `${T_PREFIX}operationType-${type}`;
        });
        const translatedOptions: { value: string; label: string }[] = translationKeys.map((key, index) => ({
            value: allowedOperationTypes[index],
            label: t(key),
        }));
        return translatedOptions;
    }
    setOtpCodeForm(otpCodeForm: CodeFormType) {
        this.setState({ ...this.state,otpCodeForm });
    }

    clearFilters = () => {
        let [ fromDate, toDate ] = DateHelper.buildDateRange();
        this.setState({
            ...this.state,
            fromDate: fromDate,
            toDate: toDate,
            operationTypeFilter: '',
            issuerFilter: '',
            amountFilter: '',
            terminalFilter: '',
            merchantFilter: "",
            paymentmodeFilter:"",
            muidFilter: "",
            userNameFilter:'',
            tranId:"",
        })
    }

    render() {
        const { t } = this.props;
        const columns = this.updateColumnDefinition()
        return (
            <><>
                <TotalTransactions rowDetail={this.state.rowDetail} link={null}></TotalTransactions>           
                {/* Modal para ingresar monto a devolver */}
                <Modal show={this.state.showModalAmount}>
                    <div className="mt-5">
                        <div className='brand-logo-container'>
                            <LogoSVG className='logo-image' ></LogoSVG>
                        </div>
                        <div className="modal-body modalTransfersReturn">
                            <div className='w-75'>
                                <p className='textAmount'>{t(`${T_PREFIX}amountReturn`)}</p>
                                <CurrencyInput
                                    className="border-start-0 ms-0 form-control inputAmount"
                                    placeholder={t(`${T_PREFIX}amount`)}
                                    allowDecimals={true}
                                    decimalsLimit={2}
                                    required
                                    disableGroupSeparators={true}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        const enteredAmount = Number((value))*100;
                                        if (!isNaN(enteredAmount) && this.state.selectedTransactions && this.state.selectedTransactions.totalAmountForReport) {
                                            const totalAmountForReport = Number(this.state.selectedTransactions.refundBalance)*100;
                                            if (enteredAmount <= totalAmountForReport) {
                                                this.setState({enteredAmount:enteredAmount});
                                                this.setState({isAmountValid:true});
                                            } else {
                                                this.setState({isAmountValid:false})
                                            }
                                        }
                                    } } />
                                {!this.state.isAmountValid && <p className="text-danger">{t(`${T_PREFIX}wrongAmount`)}</p>}
                            </div>
                        </div>
                        <div className="modalFooter">
                            <Button color="white" label={t(`${T_PREFIX}cancel`)} className="me-3" onClick={() => this.closeAmountModal()} noBlock></Button>
                            <SubmitButton onClick={() => this.handleMfaConfirmation()} disabled={!this.state.isAmountValid} noBlock>{t(`${T_PREFIX}continue`)}</SubmitButton>
                        </div>
                    </div>
                </Modal>

                {/* Modal para ingresar el codigo OTP */}
                <Modal show={this.state.showModalAuth} size="lg">
                        <div className="m-5">
                            <div className='brand-logo-container'>
                                <LogoSVG className='logo-image' ></LogoSVG>
                            </div>
                            <Form model={[this.state.otpCodeForm, this.setOtpCodeForm.bind(this)]} onSubmit={() => this.handleSubmitOTP()}>
                                <>                               
                                <div className="modal-body modalTransfersReturn">
                                    <div className='w-75'>
                                        <p className='textAmount'>{t(`${T_PREFIX}message`)}</p>
                                        <TextField attr='otpCode' label={`${T_PREFIX}code`} required autoFocus />
                                        {this.state.isOTPError && <LabelMessage valid={false} message={t(`${T_PREFIX}wrongCode`)} />}
                                    </div>
                                </div>
                                <div className="modalFooter">
                                    <Button color="white" label={t(`${T_PREFIX}cancel`)} className="me-3" onClick={() => this.closeAuthModal()} noBlock></Button>
                                    <SubmitButton label={t(`${T_PREFIX}continue`)} noBlock />
                                </div>
                                </>
                            </Form>
                        </div>
                </Modal> 

                {/* Modal para confirmar monto a devolver */}
                <Modal show={this.state.showModalConfirmation} size="lg">
                    <div className="m-5">
                        <div className='brand-logo-container'>
                                <LogoSVG className='logo-image' ></LogoSVG>
                        </div>
                        <div className="modal-body modalTransfersReturn">
                            <div className='w-50'>         
                                <p className='text-center textAmount'>{t(`${T_PREFIX}returnRequest`)}</p>
                                <p className='text-center'><span>{t("amount")} : </span>${(Number(this.state.enteredAmount)/100).toFixed(2)}</p>
                                <p className='text-center'><span>{t(`${T_PREFIX}wallet`)} :</span> {t("issuer"+"-"+(this.state.selectedTransactions?.issuer))} </p>
                                <p className='text-center'><span>{t(`${T_PREFIX}transactionType`)} :</span> {t("transactionType"+"-"+(this.state.selectedTransactions?.transactionType))} </p>
                                <p className='text-center'><span>{t(`${T_PREFIX}id`)} : </span> {this.state.selectedTransactions?.muId}</p>
                            <div className="modalFooter">
                                <Button color="white" label={t(`${T_PREFIX}cancel`)} className="me-3" onClick={() => this.closeConfirmationModal()} noBlock></Button>
                                <SubmitButton onClick={() => this.handleConfirmation(true)} noBlock>{t(`${T_PREFIX}confirm`)}</SubmitButton>
                            </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
            
            <Page>
                    <>
                        <div className='row'>
                            <h2>{t(`${T_PREFIX}title`)}</h2>
                            <div className='col-lg-12'>
                                <ErrorsView errors={this.state.errors} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <NavigationTabLinksForCharges></NavigationTabLinksForCharges>
                                    </div>
                                    <div className="card-body">
                                        <FormFilter
                                            onSubmit={this.getFromBuscar.bind(this)}
                                            model={[this.state, this.setState.bind(this)]}
                                            clear={()=>{this.clearFilters()}}
                                        >
                                            <>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <DateField attr='fromDate' label='authDateTimeFrom'></DateField>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <DateField attr='toDate' label='authDateTimeTo'></DateField>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <SelectField
                                                            attr='operationTypeFilter'
                                                            label={t(`${T_PREFIX}transactionType`)}
                                                            options={this.buildOperationTypeSelectOptions()}
                                                        ></SelectField>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <SelectField attr='issuerFilter' label={t(`${T_PREFIX}wallet`)} options={this.buildIssuerSelectOptions()}></SelectField>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <TextField attr='merchantFilter' label={t(`${T_PREFIX}merchant`)}></TextField>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <TextField attr='terminalFilter' label='terminal'></TextField>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <NumericField attr='amountFilter' label='amount'></NumericField>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <TextField attr='muidFilter' label={t(`${T_PREFIX}id`)}></TextField>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <TextField attr='userNameFilter' label={t(`${T_PREFIX}userName`)}></TextField>
                                                    </div>
                                                </div>
                                            </>
                                        </FormFilter>
                                        <div className='row'>
                                            <div className="col-md-12 accordion-table">
                                                <CustomTable
                                                    rowObjects={this.state.transactions}
                                                    columnDefinitions={columns}
                                                    config={{
                                                        mode: this.state.tableMode,
                                                        paginator: {
                                                            mainPage: this.state.mainPage,
                                                            modifyMainPage: (value: number) => {
                                                                this.setState({
                                                                    mainPage: this.state.mainPage + value
                                                                }, () => this.getTransactions());
                                                            },
                                                            pageRequest: this.state.pageRequest
                                                        },
                                                    }}
                                                ></CustomTable>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'></div>
                                            <div className="col-md-3">
                                                <CustomReCAPTCHA onVerify={this.handleCaptchaVerification} /> 
                                            </div>
                                            <div className="col-md-2">
                                                <Button className=" btn btn-block mt-3" disabled={this.state.transactions.length === 0} onClick={() => {this.getTransactionsToExcel() } }>
                                                    {t("excel")}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Page></>
        )
    };
}
export default withTranslation()(TransfersReturnReport);