import { Component } from 'react';
import NavigationTabLinks from '../containers/navigationTabLinks';
import { WebCache } from '../../services/WebCache';

class NavigationTabLinksForCharges extends Component {
    render() {
        return (
            //!WebCache.isManagementAllowed()?
            WebCache.isDevelopmentQrAllowed()?
            <NavigationTabLinks
                items={[
                    { to: '/charge/transfersReturn', icon: 'fe fe-credit-card', label: 'transfersReturn'},
                ]} 
            ></NavigationTabLinks>
            :
            <NavigationTabLinks
                items={[
                    { to: '/charge/generateQR', icon: 'fe fe-calendar', label: 'getPaidWithQr'},
                    //{ to: '/charge/transfersReturn', icon: 'fe fe-credit-card', label: 'transfersReturn'},
                ]} 
            ></NavigationTabLinks>
        )
    };
}

export default NavigationTabLinksForCharges;
