import { AxiosResponse } from 'axios';
import i18next, {t} from "i18next";
import React, { ChangeEvent } from 'react';
import { Modal } from "react-bootstrap";
import { TFunction, withTranslation } from 'react-i18next';
import { DateObject } from "react-multi-date-picker";
import { toast } from "react-toastify";
import { DateHelper } from '../../helpers/DateHelper';
import { OptionMapperHelper } from '../../helpers/OptionMapperHelper';
import { CurrencyOption } from "../../models/Currency";
import { IPageRequest } from '../../models/IPageRequest';
import {
    DateRangeType,
    IConciliationTotalsResponse,
    ISettlementTransaction,
    ISettlementTransactionRequest
} from "../../models/ISettlementData";
import { IShortcutCaptureResult } from "../../models/IShortcut";
import { IUserProfile } from '../../models/IUserProfile';
import { Option } from "../../models/Option";
import { SettlementService } from "../../services/SettlementService";
import { UtilService } from "../../services/UtilService";
import { WebCache } from '../../services/WebCache';
import CustomReCAPTCHA from '../CustomCaptcha';
import ErrorsView from '../ErrorsView';
import FormFilter from '../containers/form-filter';
import Page from '../containers/page';
import Button from '../controls/buttons/button';
import DateField from '../controls/fields/dateField';
import RadioButtonGroup from '../controls/fields/radioButtonGroup';
import SelectField from '../controls/fields/selectField';
import TextField from '../controls/fields/textField';
import TypeaheadField from '../controls/fields/typeaheadField';
import {
    ColumnDefinition,
    currencyColumnDefinition,
    dateColumnDefinition,
    numericColumnDefinition,
    percentageColumnDefinition,
    textColumnDefinition
} from '../table/ColumnDefinition';
import {ReactComponent as SearchIconSVG } from "../../../node_modules/itd-common/assets/icons/search-icon.svg";
import CustomTable, { TableModeEnum } from '../table/CustomTable';
import "./Payments.scss";
import "./SettlementReport.css";

interface IProps {
    settlementNumber: string | undefined,
    show: boolean,
    showOnlyVal: boolean,
    t: TFunction
}

interface IState {
    dateRange: DateObject[],
    msg: string,
    activePage: number,
    mainPage: number,
    onlyVal:boolean,
    transactions: ISettlementTransaction[],
    errors: string[],
    settlementNumberFilter:string,
    quotesFilter:string,
    ticketFilter:string,
    batchFilter:string,
    authorizationCodeFilter:string,
    terminalFilter:string,
    cardNumberFilter:string,
    currencyFilter:string,
    doQuery:boolean;
    dateRangeType: DateRangeType,
    currencyOptions: CurrencyOption[],
    trxToMerge:string,
    paymentToMerge:ISettlementTransaction|null,
    allStates:Option[],
    movementTypes:Option[],
    selectedStates:Option[],
    selectedMovementTypes:Option[],
    conciliationTotalResponse:IConciliationTotalsResponse,
    merchantDocuments: Array<HTMLOptionElement>,
    merchantDocumentFilter: string,
    isCaptchaVerified: boolean,
    pageRequest: IPageRequest,
    tableMode: TableModeEnum,
    pageSeing: string,
    currentUser: IUserProfile | null
}

const T_PREFIX: string = 'pages.paymentTransactionReport.';
let lastSettlementNumber: string | undefined = "";
class PaymentTransactionReport extends React.Component<IProps, IState> {
    private optionMapper: OptionMapperHelper = new OptionMapperHelper(this.props.t);
    private columnDefinitions: Array<ColumnDefinition> = [];
    private DETAILSALEPAGE: string = "settlementVouchers";
    private doQuery: boolean = false;
    componentDidMount() {
        if (this.doQuery) {
            this.doQuery = false;
            this.getSettlementTransactions(null,this.state.activePage,this.state.mainPage)
        }
    }

    componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.settlementNumber !== null && (nextProps.settlementNumber + "").length > 0 && lastSettlementNumber !== nextProps.settlementNumber) {
            lastSettlementNumber = nextProps.settlementNumber;
            this.setState({ ...this.state, doQuery:true }, this.initGrid);
        } else if (nextProps.showOnlyVal !== this.props.showOnlyVal) {
            this.getFromBuscar(nextProps.showOnlyVal)
        }
    }

    initGrid = () => {
        return this.getSettlementTransactions(this.props.settlementNumber + "", 1,0);
    }

    buildDefaultDateRange = (): Array<DateObject> => {
        const currentDay: Date = new Date();
        const createDateObject: (day: number, month: number, year: number, hour: number, minute: number, second: number) => DateObject = (
            day: number, month: number, year: number, hour: number, minute: number, second: number
        ) => {
            let dateObject = new DateObject();
            dateObject.day = day;
            dateObject.month.index = month;
            dateObject.year = year;
            dateObject.hour = hour;
            dateObject.minute = minute;
            dateObject.second = second;
            return dateObject;
        };

        const firstWeekDay: Date = new Date(currentDay.setDate(currentDay.getDate() - currentDay.getDay()));
        let fromDate: DateObject = createDateObject(firstWeekDay.getDay() - 1, firstWeekDay.getMonth(), firstWeekDay.getFullYear(), 0, 0, 0);;
        
        const lastWeekDay: Date = new Date(currentDay.setDate(currentDay.getDate() - currentDay.getDay() + 6));
        let toDate: DateObject = createDateObject(lastWeekDay.getDay() - 1, lastWeekDay.getMonth(), lastWeekDay.getFullYear(), 23, 59, 59);

        return [fromDate, toDate];
    }

    buildConciliationTotalsInitializationObject = (): IConciliationTotalsResponse => {
        return {
            grossAmount: 0,
            grossAmountUSD: 0,
            conciliatedAmount: 0,
            conciliatedAmountUSD: 0,
            pendingAmount: 0,
            pendingAmountUSD: 0,
            totalSales: 0,
            totaMovements: 0,
            conciliatedPerc: 0
        };
    }

    buildColumnDefinition = (dateToShow: string) => {
        const currency: number = Number.parseInt(WebCache.getCurrencies()[0].id);
        const flagToYesNoFn = (flag: boolean) => {
            return flag ? i18next.t('yes') : i18next.t('no');
        };

        let conditionalDateColumnDefinition: ColumnDefinition;
        switch (dateToShow) {
            default:
            case "PAYMENT_DATE":
                conditionalDateColumnDefinition = dateColumnDefinition({ key: 'vtoDate', label: 'settlement-vtoDate', header: true, percentageWidth: 10 });
            break;
            case "PRESENTATION_DATE":
                conditionalDateColumnDefinition = dateColumnDefinition({ key: 'aggregationPresentationDate', label: 'settlement-aggregationPresentationDate', header: true, percentageWidth: 10 });
            break;
            case "OPERATION_DATE":
                conditionalDateColumnDefinition = dateColumnDefinition({ key: 'authDate', label: 'settlement-authDate', header: true, percentageWidth: 10 });
            break;
        }
        
        this.columnDefinitions = [
            conditionalDateColumnDefinition,
            numericColumnDefinition({ key: 'batch', label: 'settlement-batch', header: true, percentageWidth: 10 }),
            numericColumnDefinition({ key: 'ticket', label: 'settlement-ticket', header: true, percentageWidth: 10 }),
            textColumnDefinition({ key: 'cardNumber', label: 'settlement-cardNumber', header: true, percentageWidth: 20 }),
            textColumnDefinition({ key: 'productCode', label: 'settlement-productCode', header: true, percentageWidth: 10 }),
            currencyColumnDefinition({ key: 'grossAmount', label: 'settlement-grossAmount', currency, header: true, percentageWidth: 10 }),
            numericColumnDefinition({ key: 'terminal', label: 'terminal', header: true, percentageWidth: 10 }),
            textColumnDefinition({ key: 'currency', label: 'state', header: true, mapValue: (transaction: ISettlementTransaction) => {
                let className: string = '';
                let labelKey: string = '';

                if (transaction.settlementNumber) {
                    className = 'text-success';
                    labelKey = 'payed';
                }
                else if (!(transaction.settlementNumber > 0 || new Date(transaction.originalPresentationDate) >= new Date())) {
                    className = 'text-warning';
                    labelKey = 'inprocess';
                } else if (!(transaction.settlementNumber > 0 || new Date(transaction.originalPresentationDate) < new Date())) {
                    className = 'text-danger';
                    labelKey = 'pending';
                }
                
                return <span key='li-state' className={`${className}`}>{i18next.t(`${labelKey}`).toString()}</span>;
            }}),

            numericColumnDefinition({ key: 'settlementNumber', label: 'settlementNumber' }),
            textColumnDefinition({ key: 'merchantDocument', label:  WebCache.getCurrentUser()?.countryId==2?'merchantDocument': "merchantDocumentArg"}),
            textColumnDefinition({ key: 'merchantNumber', label: 'merchantNumber' }),
            numericColumnDefinition({ key: 'quotas', label: 'quotas' }),
            numericColumnDefinition({ key: 'planQuotaType', label: 'planQuoteType' }),
            numericColumnDefinition({ key: 'movementType', label: 'movementType', mapValue: (transaction: ISettlementTransaction) => {
                let movementType: string = '';
                
                if (transaction.movementType === 5) {
                    movementType = transaction.reverseCode;
                }
                else {
                    const movementTypes: Array<Option> = i18next.t<Array<Option>>("movementTypes", { returnObjects: true });
                    const movementTypeOption: Option | undefined = movementTypes.find((movementTypeItem: Option) => Number(movementTypeItem.value) === transaction.movementType);
                    movementType = movementTypeOption?.label || '';
                }

                return movementType;
            } }),
            textColumnDefinition({ key: 'isQuotaPromotion', label: 'isQuotaPromotion', mapValue: (transaction: ISettlementTransaction) => {
                return flagToYesNoFn(transaction.isQuotaPromotion);
            }}),

            numericColumnDefinition({ key: 'authorizationCode', label: 'settlementAuthorizationCode' }),
            percentageColumnDefinition({ key: 'tariffPercentage', label: 'tariffPercentage' }),
            currencyColumnDefinition({ key: 'transactionTariffTaxAmount', label: 'transactionTariffTaxAmount', currency }),
            currencyColumnDefinition({ key: 'transactionTaxTariffTaxAmount', label: 'transactionTaxTariffTaxAmount', currency }),
            numericColumnDefinition({ key: 'planQuotaRate', label: 'planQuotaRate' }),
            currencyColumnDefinition({ key: 'financialCostAmount', label: 'financialCostAmount', currency }),
            currencyColumnDefinition({ key: 'financialCostTaxAmount', label: 'taxCostAmount', currency }),
            currencyColumnDefinition({ key: 'alícuotaIVAFO', label: 'alícuotaIVAFO', currency }),
            percentageColumnDefinition({ key: 'directRatePercentage', label: 'directRatePercentage' }),
            currencyColumnDefinition({ key: 'directRateAmount', label: 'directRateAmount', currency }),
            currencyColumnDefinition({ key: 'directRateTaxAmount', label: 'directRateTax', currency }),
            textColumnDefinition({ key: 'isCampain', label: 'isCampain', mapValue: (transaction: ISettlementTransaction) => {
                return flagToYesNoFn(transaction.isCampain);
            }}),
            //currencyColumnDefinition({ key: 'finalImport', label: 'settlementNetAmount', currency }),
            textColumnDefinition({ key: 'isCashback', label: 'isCashback', mapValue: (transaction: ISettlementTransaction) => {
                return flagToYesNoFn(transaction.isCashback);
            }}),
            textColumnDefinition({ key: 'id', label: 'isVal', mapValue: (transaction: ISettlementTransaction) => {
                return flagToYesNoFn(transaction.vtoDate !== transaction.originalVtoDate);
            }}),
            currencyColumnDefinition({ key: 'finAgp', label: 'finAgp', currency }),
            numericColumnDefinition({key: 'invoiceNumber', label: 'invoiceNumber'})
        ];
    }

    constructor(props: IProps) {
        super(props);
        let shortcut: string = "";
        if(window.location.href.indexOf("shortcuts=") > 0) {
            shortcut = new URLSearchParams(window.location.href.substring(window.location.href.indexOf("shortcuts="))).get("shortcuts")+""
        }
        
        let batchFilter: string = "";
        let ticketFilter: string = "";
        let terminalFilter: string = "";
        let authorizationCodeFilter: string = "";
        let cardNumberFilter: string = "";
        let trxToMerge: string = "";
        let dateRange: Array<DateObject> = DateHelper.buildDateRange();
        let currencyFilter: string = "";
        const merchantDocuments: Array<HTMLOptionElement> = new OptionMapperHelper(this.props.t).translateOptionValues(
            WebCache.getAllMerchantDocuments(true,true)) as Array<HTMLOptionElement>;

        if(this.props.settlementNumber!=undefined)
            this.doQuery = true;

        if (shortcut != null && shortcut.length > 0) {
            this.doQuery = true;
            let shortcutDto: IShortcutCaptureResult[] = JSON.parse(shortcut);
            for (let shortcutDtoIndex = 0; shortcutDtoIndex < shortcutDto.length; shortcutDtoIndex++) {
                switch (shortcutDto[shortcutDtoIndex].type) {
                    case "BATCH":
                        batchFilter = Number(shortcutDto[shortcutDtoIndex].data) + "";
                        break;
                    case "TICKET":
                        ticketFilter = Number(shortcutDto[shortcutDtoIndex].data) + "";
                        break;
                    case "TERMINAL_NUMBER":
                        terminalFilter = shortcutDto[shortcutDtoIndex].data + "";
                        break;
                    case "AUTHORIZATION_CODE":
                        authorizationCodeFilter = shortcutDto[shortcutDtoIndex].data + "";
                        break;
                    case "CARD_NUMBER":
                        cardNumberFilter = shortcutDto[shortcutDtoIndex].data + "";
                        break;
                    case "TRX_TO_MERGE":
                        trxToMerge = shortcutDto[shortcutDtoIndex].data + "";
                        break;
                    case "DATE":
                        dateRange = [
                            new DateObject(shortcutDto[shortcutDtoIndex].data), 
                            new DateObject(shortcutDto[shortcutDtoIndex].data)
                        ];
                        break;
                    case "CURRENCY":
                        currencyFilter = shortcutDto[shortcutDtoIndex].data + "";
                        break;
                }
            }
        }

        this.state = {
            onlyVal: this.props.showOnlyVal,
            dateRangeType: "PAYMENT_DATE",
            dateRange,
            msg: "",
            activePage: 1,
            mainPage: 0,
            transactions: [],
            errors: [],
            settlementNumberFilter: "",
            quotesFilter: "",
            currencyFilter,
            authorizationCodeFilter,
            batchFilter,
            ticketFilter,
            terminalFilter,
            cardNumberFilter,
            currencyOptions: UtilService.getCurrencyOptions().filter(row => row.value !== ""),
            doQuery: false,
            trxToMerge,
            paymentToMerge: null,
            allStates:[
                { key: "inprocess", label: i18next.t("inprocess"), value: "inprocess", disabled: false },
                { key: "pending", label: i18next.t("pending"), value: "pending", disabled: false },
                { key: "payed", label: i18next.t("payed"), value: "payed", disabled: false }
            ],
            selectedStates: [],
            movementTypes: i18next.t<Array<Option>>("movementTypes", { returnObjects: true }).map((movementType, movementTypeIndex) => {
                return { key: String(movementTypeIndex), ...movementType, disabled: false };
            }),
            selectedMovementTypes: [],
            conciliationTotalResponse: this.buildConciliationTotalsInitializationObject(),
            merchantDocuments,
            merchantDocumentFilter: merchantDocuments[0].value,
            isCaptchaVerified: false,
            tableMode: TableModeEnum.LIST,
            pageRequest: {
                Skip: 0,
                Take: 0
            },
            pageSeing: "",
            currentUser : WebCache.getCurrentUser()
        }
        
        this.buildColumnDefinition("PAYMENT_DATE");
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        //cuando se cambia de url, se limpia la pagina 
        let thatPage: string = window.location.href.split("/")[window.location.href.split("/").length-1];
        if(this.state.pageSeing != thatPage){
            let dateRange: Array<DateObject> = DateHelper.buildDateRange();
            const merchantDocuments: Array<HTMLOptionElement> = new OptionMapperHelper(this.props.t).translateOptionValues(WebCache.getAllMerchantDocuments(
                true,true)) as Array<HTMLOptionElement>;
            this.setState({
                ...this.state,
                onlyVal: this.props.showOnlyVal,
                dateRangeType: "PAYMENT_DATE",
                dateRange: this.state.dateRange,
                msg: "",
                activePage: 1,
                mainPage: 0,
                transactions: [],
                errors: [],
                settlementNumberFilter: "",
                quotesFilter: "",
                currencyFilter: this.state.currencyFilter,
                authorizationCodeFilter: "",
                batchFilter: "",
                ticketFilter: "",
                terminalFilter: "",
                cardNumberFilter: "",
                currencyOptions: UtilService.getCurrencyOptions().filter(row => row.value !== ""),
                doQuery: false,
                trxToMerge: "",
                paymentToMerge: null,
                allStates:[
                    { key: "inprocess", label: i18next.t("inprocess"), value: "inprocess", disabled: false },
                    { key: "pending", label: i18next.t("pending"), value: "pending", disabled: false },
                    { key: "payed", label: i18next.t("payed"), value: "payed", disabled: false }
                ],
                selectedStates: [],
                movementTypes: i18next.t<Array<Option>>("movementTypes", { returnObjects: true }).map((movementType, movementTypeIndex) => {
                    return { key: String(movementTypeIndex), ...movementType, disabled: false };
                }),
                selectedMovementTypes: [],
                conciliationTotalResponse: this.buildConciliationTotalsInitializationObject(),
                merchantDocuments,
                merchantDocumentFilter: merchantDocuments[0].value,
                isCaptchaVerified: false,
                tableMode: TableModeEnum.LIST,
                pageRequest: {
                    Skip: 0,
                    Take: 0
                },
                pageSeing: thatPage,
                currentUser : WebCache.getCurrentUser()
            });
        }
    }

    getFromBuscar = (onlyVal:boolean) => {
        this.setState({
            ...this.state, activePage: 1, mainPage: 0, onlyVal
        }, ()=>{
            this.getSettlementTransactions(null, this.state.activePage, this.state.mainPage);
        });
    }

    clearFilters = () => {
        this.setState({
            ...this.state,
            dateRange: DateHelper.buildDateRange(),
            currencyFilter: "",
            settlementNumberFilter: "",
            quotesFilter: "",
            selectedStates: [],
            authorizationCodeFilter: "",
            ticketFilter: "",
            batchFilter: "",
            terminalFilter: "",
            cardNumberFilter: "",
            selectedMovementTypes: [],
            merchantDocumentFilter: ""
        });                       
    }

    getSettlementTransactionsRequest = (all: boolean): ISettlementTransactionRequest => {
        let fromDate: string = '';
        let toDate: string = '';
        
        if (this.state.dateRange[0]) {
            let from_year = this.state.dateRange[0].year;
            let from_month = this.state.dateRange[0].month.number;
            let from_day = this.state.dateRange[0].day;
            fromDate = new Date(from_year, from_month - 1, from_day).toJSON();
        }

        if (this.state.dateRange[1]) {
            let to_year = this.state.dateRange[1].year;
            let to_month = this.state.dateRange[1].month.number;
            let to_day = this.state.dateRange[1].day;
            toDate = new Date(to_year, to_month - 1, to_day).toJSON();
        }

        if (this.props.settlementNumber === undefined) {
            return {
                From: fromDate,
                To: toDate,
                DateRangeType: this.state.dateRangeType,
                SettlementNumber: (this.state.settlementNumberFilter === null || this.state.settlementNumberFilter === "") ? "" : this.state.settlementNumberFilter,
                Ticket: (this.state.ticketFilter === null || this.state.ticketFilter === "") ? "" : this.state.ticketFilter,
                Batch: (this.state.batchFilter === null || this.state.batchFilter === "") ? "" : this.state.batchFilter,
                Quotas: (this.state.quotesFilter === null || this.state.quotesFilter === "") ? "" : this.state.quotesFilter,
                States: (this.state.selectedStates === null) ? [] : this.state.selectedStates.map(option => option.key),
                MovementTypes: (this.state.selectedMovementTypes === null) ? [] : this.state.selectedMovementTypes.map(option => (option.key) ? Number.parseInt(option.key) : undefined),
                AuthorizationCode: (this.state.authorizationCodeFilter === null || this.state.authorizationCodeFilter === "") ? "" : this.state.authorizationCodeFilter,
                TerminalId: (this.state.terminalFilter === null || this.state.terminalFilter === "") ? "" : this.state.terminalFilter,
                CardNumber: (this.state.cardNumberFilter === null || this.state.cardNumberFilter === "") ? "" : this.state.cardNumberFilter,
                Currency: (this.state.currencyFilter === null || this.state.currencyFilter === "") ? "" : this.state.currencyFilter,
                OnlyVal: this.state.onlyVal,
                Skip: all ? 0 : (this.state.pageRequest.Skip),
                Take: all ? 0 : (this.state.pageRequest.Take),
                MerchantDocument: this.state.merchantDocumentFilter
            };
        } else {
            return {
                DateRangeType: this.state.dateRangeType,
                SettlementNumber: this.props.settlementNumber,
                Ticket: "",
                Batch: "",
                Quotas: "",
                AuthorizationCode: "",
                Currency: "",
                OnlyVal: this.props.settlementNumber!=undefined?null:this.state.onlyVal,
                TerminalId: "",
                Skip: all ? 0 : (this.state.pageRequest.Skip),
                Take: all ? 0 : (this.state.pageRequest.Take),
                MerchantDocument: this.state.merchantDocumentFilter
            };
        }
    }

    getSettlementTransactions = (lastSettlementNumber:string|null,activePage:number,mainPage:number) => {
        let request=this.getSettlementTransactionsRequest(false);
        if(lastSettlementNumber!=null)
            request.SettlementNumber=lastSettlementNumber+"";
        SettlementService.getSettlementTransactions(request).then((response: AxiosResponse<ISettlementTransaction[]>) => {
            if(!response || !response.data || response.data.length === 0) {
                this.setState({...this.state,
                    transactions: [],
                    errors: [],
                    activePage:activePage,
                    mainPage:mainPage,
                    doQuery:false,
                    conciliationTotalResponse: this.buildConciliationTotalsInitializationObject()
                })
                return;
            }

            const filteredTransactions = response.data;

            this.setState({...this.state,
                transactions: filteredTransactions,
                errors: [],
                activePage: activePage,
                mainPage: mainPage,
                doQuery: false
            },()=> {
                SettlementService.getConciliationTotals(request).then((concResponse: AxiosResponse<IConciliationTotalsResponse>) => {
                    this.setState({
                        ...this.state,
                        conciliationTotalResponse: concResponse.data
                    });
                });
            });
        }).catch(error => {
                this.setState({...this.state,
                    errors: error.response.data,
                    activePage:activePage,
                    mainPage:mainPage,
                    doQuery:false
                });
                this.setState({...this.state,
                    transactions: [],
                    activePage:activePage,
                    mainPage:mainPage,
                    doQuery:false
                })
                console.log('error:', error);
            }
        );
    }


    handleCaptchaVerification = (verified: boolean) => {
        this.setState({ isCaptchaVerified: verified });
    };

    getSettlementsToExcel = () => {
        if (this.state.isCaptchaVerified) {
            SettlementService.getAllTransactionsToExcel(this.getSettlementTransactionsRequest(true)).then((response: AxiosResponse<Blob>) => {
        }).catch(error => {
            this.setState({...this.state,errors: error.response.data});
            console.log('error: ', error);
        });
        }else {
            toast.error("Por favor, verifica el CAPTCHA antes de descargar el archivo");
        };
    }

    updateDateRange = (dateObject: DateObject[] | any) => {
        this.setState({ ...this.state, dateRange: dateObject });
    }

    onDateRangeTypeChange = (event: DateRangeType) => {
        this.buildColumnDefinition(event);
    }

    mergeTrx = (transaction:ISettlementTransaction)=> {
        this.setState({...this.state, paymentToMerge: transaction});
    }

    confirmMergeTrx=()=> {
        SettlementService.mergeTrx({ paymentId: this.state.paymentToMerge?.id, transactionId: this.state.trxToMerge }).then((response: any) => {
            toast.success("transactionMerged");
            this.setState({...this.state,paymentToMerge:null});
            window.location.href = "/#/conciliation/calendar";
        })
    }

    render() {
        // @ts-ignore
        const { t } = this.props;
        if(!this.props.show)
            return (<></>);
        else
            return (
                <Page>
                    <>
                        <Modal show={this.state.paymentToMerge!=null}>
                            <div className="card border p-0 pb-3">
                                <div className="card-header border-0 pt-3">
                                    <div className="card-options">
                                        <a href="javascript:void(0)" className="card-options-remove" onClick={() => this.setState({ paymentToMerge: null })} ><i className="fe fe-x"></i></a>
                                    </div>
                                </div>
                                <div className="card-body text-center">
                                <span className=""><svg xmlns="http://www.w3.org/2000/svg" height="60" width="60"
                                                        viewBox="0 0 24 24"><path fill="#f07f8f"
                                                                                d="M20.05713,22H3.94287A3.02288,3.02288,0,0,1,1.3252,17.46631L9.38232,3.51123a3.02272,3.02272,0,0,1,5.23536,0L22.6748,17.46631A3.02288,3.02288,0,0,1,20.05713,22Z" /><circle
                                    cx="12" cy="17" r="1" fill="#e62a45" /><path fill="#e62a45"
                                                                                d="M12,14a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0v4A1,1,0,0,1,12,14Z" /></svg></span>
                                    <h4 className="h4 mb-0 mt-3">{t('warning')}</h4>
                                    <p className="card-text">{t('confirmMergeTrx')}</p>
                                </div>
                                <div className="card-footer text-center border-0 pt-0">
                                    <div className="row">
                                        <div className="text-center">
                                            <a href={window.location.href} className="btn btn-white me-2" onClick={() => this.setState({ paymentToMerge: null })}>{t('cancel')}</a>
                                            <a href={window.location.href} className="btn btn-danger" onClick={() => this.confirmMergeTrx()}>{t('confirm')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <ErrorsView errors={this.state.errors}/>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-lg-12">
                                    <div className='border-bottom'  hidden={this.props.settlementNumber!=undefined}>
                                        {
                                            (this.state.pageSeing == this.DETAILSALEPAGE) || (this.state.pageSeing.startsWith('settlementVouchers?shortcuts')) ?
                                                <h2 className="title">{t("saleDetail")}</h2>
                                                :
                                                <h2 className="title">{t("advanceDetail")}</h2>
                                        }
                                    </div>
                                    <div className="filter-accordion pe-5">
                                        <section className='mt-3 mb-3' hidden={this.props.settlementNumber!=undefined}>
                                                <div className="row">
                                                    <div className="d-flex justify-content-start pt-2 mb-4">
                                                        <RadioButtonGroup 
                                                            model={[ this.state, this.setState.bind(this) ]} 
                                                            attr="dateRangeType" 
                                                            className='radioStyle'
                                                            fieldDefinitions={[
                                                                { key: 'originalPresentationDate', value: 'PAYMENT_DATE', label: 'settlement-vtoDate' },
                                                                { key: 'authDate', value: 'OPERATION_DATE', label: 'settlement-authDate' },
                                                                { key: 'aggregationPresentationDate', value: 'PRESENTATION_DATE', label: 'settlement-aggregationPresentationDate' }
                                                            ]}
                                                            onChange={(event: ChangeEvent<HTMLInputElement>) => this.onDateRangeTypeChange(event.target.value as DateRangeType) }
                                                        ></RadioButtonGroup>
                                                    </div>
                                                </div>
                                                <FormFilter hidden={true} model={[ this.state, this.setState.bind(this) ]} onSubmit={() => this.getFromBuscar(this.props.showOnlyVal)} clear={this.clearFilters}>
                                                        <div className="row form-filter" hidden={this.props.settlementNumber !== undefined}>
                                                            <div className="col-md-10 mb-1">
                                                                <DateField attr="dateRange" label="dateRange" range ></DateField>
                                                            </div>
                                                            <div className="col-md-10 mb-1" hidden={this.state.currentUser?.countryId === 1} > 
                                                                <SelectField attr='currencyFilter' label='currency' options={this.state.currencyOptions} ></SelectField>
                                                            </div>
                                                            <div className="col-md-10 mb-1">
                                                                <TextField attr='settlementNumberFilter' label='settlementNumber' sufix={<SearchIconSVG className='mt-3 me-2'></SearchIconSVG>}></TextField>
                                                            </div>
                                                            <div className="col-md-10 mb-1">
                                                                <TextField attr='quotesFilter' label='quotas' sufix={<SearchIconSVG className='mt-3 me-2'></SearchIconSVG>}></TextField>
                                                            </div>
                                                            <div className="col-md-10 mb-1">
                                                                <TypeaheadField attr="selectedStates" label="state" options={this.state.allStates} ></TypeaheadField>
                                                            </div>
                                                            {/* <div className="col-md-1">
                                                            </div> */}
                                                            <div className="col-md-10 mb-1">
                                                                <TextField attr='authorizationCodeFilter' label='authorizationCode' sufix={<SearchIconSVG className='mt-3 me-2'></SearchIconSVG>}></TextField>
                                                            </div>
                                                            <div className="col-md-10 mb-1">
                                                                <TextField attr='ticketFilter' label='ticket' sufix={<SearchIconSVG className='mt-3 me-2'></SearchIconSVG>}></TextField>
                                                            </div>
                                                            <div className="col-md-10 mb-1">
                                                                <TextField attr='batchFilter' label='batch' sufix={<SearchIconSVG className='mt-3 me-2'></SearchIconSVG>}></TextField>
                                                            </div>
                                                            <div className="col-md-10 mb-1">
                                                                <TextField attr='terminalFilter' label='terminal' sufix={<SearchIconSVG className='mt-3 me-2'></SearchIconSVG>}></TextField>
                                                            </div>
                                                            <div className="col-md-10 mb-1">
                                                                <TextField attr='cardNumberFilter' label='cardNumber' sufix={<SearchIconSVG className='mt-3 me-2'></SearchIconSVG>}></TextField>
                                                            </div>
                                                            {/* <div className="col-md-2">
                                                            </div> */}
                                                            <div className="col-md-10 mb-1">
                                                                <TypeaheadField attr="selectedMovementTypes" label="movementType" options={this.state.movementTypes} ></TypeaheadField>
                                                            </div>
                                                            <div className="col-md-10 mb-1">
                                                                <SelectField attr='merchantDocumentFilter' label={this.state.currentUser?.countryId === 2 ? `${T_PREFIX}merchantDocumentROU` : `${T_PREFIX}merchantDocumentARG`} options={this.state.merchantDocuments} ></SelectField>
                                                            </div>
                                                        </div>
                                                    </FormFilter>
                                        </section>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <div className='border-top' hidden={this.props.settlementNumber === undefined}>
                                                        <h3 className="title p-4">{this.props.showOnlyVal?t("advanceDetail"):t("settlementTransactions")+" "+this.props.settlementNumber}</h3>
                                                    </div>
                                                    <div className='border-top' hidden={this.props.settlementNumber !== undefined}>
                                                        <h3 className="title p-4">{this.props.showOnlyVal?t("advanceDetail"):t("vouchers")}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pb-4 ms-1">
                                                {/* <div className="col-md-12 d-flex align-items-center"> */}
                                                    <div className="col-md-2 div-info div-info-width-5" style={{textAlign:"start"}}>
                                                        <label className="form-label"> {t('grossAmountCamelCase')}</label>
                                                        <label hidden={this.state.conciliationTotalResponse.grossAmount==0}  className="border-start-0 ms-0 amount-label" >{
                                                            Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(this.state.conciliationTotalResponse.grossAmount)
                                                        }</label>
                                                        <label hidden={this.state.conciliationTotalResponse.grossAmountUSD==0}  className="border-start-0 ms-0 amount-label" >{
                                                            Intl.NumberFormat('es-UY', { style: 'currency', currency: "USD" }).format(this.state.conciliationTotalResponse.grossAmountUSD)
                                                        }</label>
                                                    </div>
                                                    <div className="col-md-2 div-info div-info-width-5 ms-md-5" style={{textAlign:"start"}}>
                                                        <label className="form-label"> {t('conciliated')}</label>
                                                        <label hidden={this.state.conciliationTotalResponse.conciliatedAmount==0}  className="border-start-0 ms-0 amount-label" >{
                                                            Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(this.state.conciliationTotalResponse.conciliatedAmount)
                                                        }</label>
                                                        <label hidden={this.state.conciliationTotalResponse.conciliatedAmountUSD==0}  className="border-start-0 ms-0 amount-label" >{
                                                            Intl.NumberFormat('es-UY', { style: 'currency', currency: "USD" }).format(this.state.conciliationTotalResponse.conciliatedAmountUSD)
                                                        }</label>
                                                    </div>
                                                    <div className="col-md-2 div-info div-info-width-5 ms-md-5" style={{textAlign:"start"}}>
                                                        <label className="form-label"> {t('pending')}</label>
                                                        <label hidden={this.state.conciliationTotalResponse.pendingAmount==0}  className="border-start-0 ms-0 amount-label" >{
                                                            Intl.NumberFormat('es-UY', { style: 'currency', currency: "UYU" }).format(this.state.conciliationTotalResponse.pendingAmount)
                                                        }</label>
                                                        <label hidden={this.state.conciliationTotalResponse.pendingAmountUSD==0}  className="border-start-0 ms-0 amount-label" >{
                                                            Intl.NumberFormat('es-UY', { style: 'currency', currency: "USD" }).format(this.state.conciliationTotalResponse.pendingAmountUSD)
                                                        }</label>
                                                    </div>
                                                    <div className="col-md-2 div-info div-info-width-5 ms-md-5" style={{textAlign:"start"}}>
                                                        <label className="form-label"> {t('totalSales')}</label>
                                                        <label className="border-start-0 ms-0 amount-label" >{
                                                            Intl.NumberFormat('es-UY').format(this.state.conciliationTotalResponse.totalSales)+" "+t('coupons')
                                                        }</label>
                                                    </div>
                                                    <div className="col-md-2 div-info div-info-width-5 ms-md-5" style={{textAlign:"start"}}>
                                                        <label className="form-label"> {t('state')}</label>
                                                        <label className="border-start-0 ms-0 amount-label" >
                                                            {`${Intl.NumberFormat('es-UY', {maximumFractionDigits:0}).format(Number(this.state.conciliationTotalResponse.conciliatedPerc*100))}%`}
                                                        </label>
                                                    </div>
                                                {/* </div> */}
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-12 accordion-table accordion-table-tax">
                                                    <CustomTable
                                                        rowObjects={this.state.transactions}
                                                        columnDefinitions={this.columnDefinitions}
                                                        config={{
                                                            mode: this.state.tableMode,
                                                            paginator: {
                                                                mainPage: this.state.mainPage,
                                                                modifyMainPage: (value: number) => {
                                                                    this.setState({
                                                                        mainPage: this.state.mainPage + value
                                                                    }, () => this.getSettlementTransactions(null,this.state.activePage, this.state.mainPage))
                                                                },
                                                                pageRequest: this.state.pageRequest
                                                            },
                                                            customButtonAction: this.state.trxToMerge !== "" ? { action: this.mergeTrx, buttonText: t("mergeTransaction") } : undefined
                                                        }}
                                                    ></CustomTable>
                                                </div>
                                            </div>
                                            <div className='row ps-4 pe-4'>
                                                <div className='col-md-6'></div>
                                                <div className="col-md-3">
                                                    <CustomReCAPTCHA onVerify={this.handleCaptchaVerification} /> 
                                                </div>
                                                <div className="col-md-3">
                                                    <Button disabled={this.state.transactions.length === 0} onClick={this.getSettlementsToExcel}>
                                                        {t("excel")}
                                                    </Button>
                                                </div>
                                            </div>
                                    </div>
                            </div>
                        </div>
                    </>
                </Page>
            )
    }; 
}
export default withTranslation() (PaymentTransactionReport);
